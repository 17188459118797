<template>
  <Page :title="title" :titleCaption="titleShadow" class="install-bg">
    <v-row class="install-box-bg my-10" justify="center" style="width: 100%;">
      <v-col
        lg="9"
        cols="11"
        class="install-inner-bg my-md-10"
      >
        <v-row justify="center" class="py-10">
          <v-col md="5" cols="11" class="d-flex flex-column">
            <div :class="titleFont" class="align-self-center align-self-md-start">Android</div>
            <div :class="contentFont" class="justified mt-10">
              <ol class="ml-n5">
                <li>Access our <a href="https://groups.google.com/g/timeworx-closed-testing" target="_blank">Google Group</a></li>
                <li>Click on <span style="color: var(--accent)">Join Group</span></li>
                <li>Install <a href="https://play.google.com/store/apps/details?id=io.timeworx.agent" target="_blank">Timeworx.io</a> from the Play Store</li>
              </ol>
              <p class="mt-8">Please make sure that you subscribe to the Google Group using your primary Google account as listed in the Play Store.</p>
            </div>
          </v-col>
          <v-col md="1" cols="0" />
          <v-col md="5" cols="11" class="d-flex flex-column">
            <div :class="titleFont" class="align-self-center align-self-md-start">iOS</div>
            <div :class="contentFont" class="justified mt-10">
              <ol class="ml-n5">
                <li>Install <a href="https://testflight.apple.com/join/JTzlNn5X" target="_blank">TestFlight</a></li>
                <li>Accept the invitation</li>
                <li>Install <span style="color: var(--accent)">Timeworx.io</span> from TestFlight</li>
              </ol>
              <p class="mt-8">Please make sure to follow steps 2 and 3 from the TestFlight page opened at step 1.</p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from "../Page.vue";

export default {
  name: "InstallMobile",
  metaInfo: {
    title: "How to install the mobile app"
  },
  data() {
    return {
      title: "Install the mobile apps",
      titleShadow: "How to"
    };
  },
  components: { Page },
  computed: {
    contentFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-18px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-16px";
          } else {
            return "poppins-normal-white-15px";
          }
        case "xs":
          return "poppins-normal-white-15px";
      }
    },
    titleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-50px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-34px";
          } else {
            return "poppins-bold-white-30px";
          }
        case "xs":
          return "poppins-bold-white-25px";
      }
    },
  },
};
</script>

<style scoped>
.install-bg {
  background: transparent linear-gradient(275deg, #18002e 0%, #310293 100%) 0%
    0% no-repeat padding-box;
}

.install-box-bg {
  background: #6248C4 0% 0% no-repeat padding-box;
  position: relative;
  overflow-y: hidden;
}

.install-inner-bg {
  background: transparent linear-gradient(294deg, #18002e 0%, #310293 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000029;
  z-index: 1;
}

a:link { text-decoration: none; color: var(--accent); }
a:visited { text-decoration: none; color: var(--accent); }
a:hover { text-decoration: none; color: var(--accent); }
a:active { text-decoration: none; color: var(--accent); }

.justified {
  text-align: justify;
  text-justify: inter-word;
}

ol {
  list-style: none;
  counter-reset: item;
}

li {
  margin-top: 10px;
  counter-increment: item;
}

li:before {
   margin-right: 15px;
   content: counter(item);
   background: var(--accent);
   border-radius: 100%;
   color: white;
   width: 1.5em;
   text-align: center;
   display: inline-block;
 }
</style>
