<template>
  <div class="d-flex flex-column align-start">
    <Category
      v-for="item in items"
      :key="item.label"
      :item="item"
      v-bind:isGlowing="glowingItems[item.label]"
      @click="onItemClick(item)"
      class="mb-6"
    />
  </div>
</template>

<script>
import Vue from "vue";
import Category from "./Category.vue";

export default {
  name: "Categories",
  components: {
    Category,
  },
  props: ["items"],
  data() {
    return {
      glowingItems: this.items.map((el) => ({ [el.label]: false })),
    };
  },
  created() {
        Vue.set(this.glowingItems, this.items[0].label, true);
  },
  methods: {
    onItemClick(item) {
      this.items.forEach(it => {
        Vue.set(this.glowingItems, it.label, false);
      });
      Vue.set(this.glowingItems, item.label, true);
      this.$emit("selectedItem", item);
    },
  },
};
</script>

<style></style>
