<template>
  <v-card class="round">
    <v-container class="ma-0 pa-0">
      <v-row no-gutters justify="center">
        <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12" align="end">
          <v-btn icon class="pa-8" @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12" md="6" class="alpha-registration-left mt-md-16">
          <div :class="titleBoldFont" style="text-align: center;">{{titleLine1}}</div>
          <div :class="[titleFont, 'mt-md-n2']" style="text-align: center;">{{titleLine2}}</div>
          <div class="poppins-normal-on-surface-variant-14px alpha-body mt-5 mt-md-10 mx-7 px-0 mx-md-16 px-md-4">{{body}}</div>

          <v-form ref="form" v-model="valid" lazy-validation class=" mx-7 px-0 mx-md-16 px-md-4">
            <v-row no-gutters justify="center" class="mt-3">
              <v-col align="center">
                <v-radio-group v-model="os" :row="$vuetify.breakpoint.smAndDown">
                  <v-radio class="radio-bg-surface-variant mb-md-4 px-4 px-md-0" value="android" color="#ff6200 !important" background-color="white">
                    <template v-slot:label>
                      <div :class="radioFont">Android</div>
                    </template>
                  </v-radio>
                  <v-radio class="radio-bg-surface-variant px-4 px-md-0" value="ios" color="#ff6200 !important">
                    <template v-slot:label>
                      <div :class="radioFont">iOS</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" class="pa-0">
                <v-text-field
                  label="Name"
                  background-color="white"
                  rounded
                  outlined
                  :rules="nameRules"
                  required
                  v-model="name"
                  class="mr-md-5"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" class="pa-0">
                <v-text-field
                  :label="os == 'android' ? 'Google address' : 'Apple ID'"
                  background-color="white"
                  rounded
                  outlined
                  :rules="emailRules"
                  required
                  v-model="email"
                  class="ml-md-5"
                ></v-text-field>
              </v-col>
            </v-row>
      
            <v-row justify="center" class="mb-md-16">
              <v-col align="center">
                <v-btn
                    v-if="!loading"
                    elevation="2"
                    rounded
                    color="#3B00A0"
                    style="text-transform: unset !important"
                    @click="submit"
                    class="px-6 mt-n3 mt-md-0">
                      <span class="poppins-normal-white-14px">{{ button }}</span>
                  </v-btn>
                <v-progress-circular
                  indeterminate
                  color="#ff6200"
                  v-if="loading"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-checkbox
              v-model="allowMarketing"
              :label="marketing"
              class="alpha-marketing-checkbox mt-7 mt-md-16 mb-xl-12"
            ></v-checkbox>
          </v-form>
        </v-col>
        
        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6" class="alpha-registration-right">
          <v-img
                  :src="img"
                  :lazySrc="lazyImg"
                  contain
                ></v-img>
          <v-btn class="pa-8" @click="$emit('close')" fab icon top right style="position: absolute; top: 0; right: 0">
              <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" absolute>
        {{ snackbarMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="#ff6200"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
        </v-snackbar>
    </v-container>
  </v-card>
</template>

<script>
import { httpsCallable } from '@firebase/functions';
import { functions } from '../firebase/config';
import Button from './Button.vue';
const config = require("@/config");

export default {
  name: "AlphaRegistration",
  components: {
    Button,
  },
  props: [
    "titleLine1",
    "titleLine2",
    "body",
    "button",
    "img",
    "lazyImg",
    "successMessage",
    "errorMessage",
    "marketing"
  ],
  data() {
    return {
      name: "",
      email: "",
      os: "android",
      valid: true,
      allowMarketing: false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      nameRules: [
        v => !!v || 'Name is required',
      ],
      snackbar: false,
      snackbarMessage: '',
      loading: false,
    };
  },
  computed: {
    titleBoldFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-blue-gem-42px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-blue-gem-42px";
          } else {
            return "poppins-bold-blue-gem-24px";
          }
        case "xs":
          return "poppins-bold-blue-gem-24px";
      }
    },
    titleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-semi-bold-black-36px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-semi-bold-black-36px";
          } else {
            return "poppins-semi-bold-black-20px";
          }
        case "xs":
          return "poppins-semi-bold-black-20px";
      }
    },
    radioFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-on-surface-16px";
        default:
          return "poppins-normal-on-surface-16px";
      }
    },
  },
  methods: {
    submit () {
      this.$refs.form.validate();
      console.log({
            name: this.name,
            email: this.email,
            os: this.os,
            marketing: this.allowMarketing
          })
      setTimeout(() => {
        if (this.valid) {
          this.loading = true;
          const alphaRegistration = httpsCallable(functions, config.functions.ALPHA_REGISTRATION);
          alphaRegistration({
            name: this.name,
            email: this.email,
            os: this.os,
            marketing: this.allowMarketing
          })
          .then((result) => {
            this.snackbarMessage = this.successMessage;
            this.snackbar = true;
            this.loading = false;

            this.$gtm.trackEvent({
              event: 'subscribe-alpha',
              category: 'Subscribe',
              action: 'click',
              label: 'subscribe-alpha',
              value: 0,
              noninteraction: false, // Optional
            });
          })
          .catch((error) => {
            this.snackbarMessage = this.errorMessage;
            this.snackbar = true;
            console.log(error);
            this.loading = false;
          });
        }
      });
    },
  }
};
</script>

<style>
.radio-bg-surface-variant .v-icon {
  color: 48454E !important;
}

.alpha-registration-left {
  
}

.alpha-registration-right {
  background: #330694;
}

.alpha-body {
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  justify-content: center;
  white-space: pre-wrap;
}

.alpha-marketing-checkbox .v-label {
  color: #79757F;
  font-family: var(--font-family-poppins);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
} 

::v-deep .alpha-marketing-checkbox .v-label {
  color: #79757F;
  font-family: var(--font-family-poppins);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}

.round {
  max-width: 1200px;
  border-radius: 30px;
}
</style>
