import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");
const config = require("@/config");

const firebaseConfig = {
    apiKey: "AIzaSyASIw1x5DVBZb6nO1oNbaFjp8oCwr1GyCg",
    authDomain: "timeworx-36b8f.firebaseapp.com",
    databaseURL: "https://timeworx-36b8f-default-rtdb.firebaseio.com",
    projectId: "timeworx-36b8f",
    storageBucket: "timeworx-36b8f.appspot.com",
    messagingSenderId: "851077055686",
    appId: "1:851077055686:web:55233ab1d81308c896c3c7",
    measurementId: "G-0Z4QXT93H6"
  };
  
const app = initializeApp(firebaseConfig);
if (config.hasAppCheck) {
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LcPaMMgAAAAACmo5KbAuJMcnmoWIfIpkCF70gJv'),
    isTokenAutoRefreshEnabled: true
  });
}
const analytics = getAnalytics(app);
const functions = getFunctions(app, config.functions.BASE_URL);

export { analytics, functions }