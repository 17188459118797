<template>
    <div :class="background">
        <v-container class="page-container">
            <v-row :class="top" class="align-center">
                <v-col>
                    <v-row>
                        <v-col>
                            <h1 :class="heading">The <span class="heading-span">data processing</span><br />layer for
                                next gen AI</h1>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col :class="subheading" class="mt-md-2 pa-0">
                            <span>built on </span><a href="https://injective.com/" target="_blank"><img class="mx-1 mx-md-2 logo-inj"
                                :src="require('@/assets/img/home/logo_injective.svg')" :height="injLogoHeight" /></a><span>and</span><a
                                href="https://multiversx.com/" target="_blank"><img class="mx-1 mx-md-2 logo-mvx"
                                :src="require('@/assets/img/home/logo_multiversx.svg')" :height="mvxLogoHeight" /></a><br v-if="$vuetify.breakpoint.smAndDown" /><span>(and more to come)
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col md="6">
                    <router-link to="/community">
                        <div class="pa-5 pa-md-10 mr-md-8 community-card card highlight">
                            <div :class="cardHeadline">Get rewarded</div>
                            <div :class="cardBody" class="mt-5 mt-md-10">Did you ever think you could shape the future of AI while earning
                                money without any prior training?</div>
                            <div class="text-center mt-5 mt-md-10">
                                <v-btn rounded class="cta pa-md-7">
                                    <span :class="cardButton">
                                        Join our community
                                    </span>
                                    <v-icon right :size="$vuetify.breakpoint.mdAndUp ? '20px' : '16px'" dark class="ml-1 ml-md-4">mdi-arrow-right</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </router-link>
                </v-col>
                <v-col md="6">
                    <router-link to="/platform">
                        <div class="pa-5 pa-md-10 ml-md-8 business-card card highlight">
                            <div :class="cardHeadline">Label your data</div>
                            <div :class="cardBody" class="mt-5 mt-md-10">Did you ever imagine tapping into a digital workforce to label your
                                data at the click of a button?</div>
                            <div class="text-center mt-5 mt-md-10">
                                <v-btn rounded class="cta pa-md-7" append-icon="arrow-right">
                                    <span :class="cardButton">
                                        Discover our platform
                                    </span>
                                    <v-icon right :size="$vuetify.breakpoint.mdAndUp ? '20px' : '16px'" dark class="ml-1 ml-md-4">mdi-arrow-right</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </router-link>
                </v-col>
            </v-row>
            <v-row class="partners my-5">
                <v-col>
                    <PartnersDark class="my-md-10" />
                    <hr class="separator" />
                </v-col>
            </v-row>
        </v-container>
        <div :class="content">
            <v-container class="page-container">
                <v-row class="mb-5 my-md-10 px-5">
                    <v-col class="justify-center">
                        <div :class="mission">Together we can bridge the gap between human and artificial intelligence and
                            solve the ground truth for AI</div>
                    </v-col>
                </v-row>
                <v-row :class="section" class="my-5 my-md-10 w-100 highlight">
                    <v-col>
                        <v-row>
                            <v-col>
                                <h2 :class="sectionH2">Digital transformation for your business</h2>
                            </v-col>
                        </v-row>
                        <v-row class="mb-md-10">
                            <v-col>
                                <h3 :class="sectionH3">Transition from human data processing to automated data processing</h3>
                            </v-col>
                        </v-row>
                        <v-row class="my-10" no-gutters>
                            <v-col class="align-self-center" cols="12" lg="4">
                                <v-row>
                                    <v-col class="my-md-3 my-lg-10" cols="12">
                                        <h4 :class="sectionH4">Composability</h4>
                                        <div :class="sectionDiv" class="mt-1 mt-lg-6">Effortlessly define your data processing pipelines according to
                                            your
                                            needs</div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="my-md-3 my-lg-10" cols="12">
                                        <h4 :class="sectionH4">AI-Assistance</h4>
                                        <div :class="sectionDiv" class="mt-1 mt-lg-6">Take advantage of the builtin capabilities for AI-assisted data
                                            processing</div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="4">
                                <v-img contain :src="require('@/assets/img/home/data_processing.png')" width="428px" />
                            </v-col>
                            <v-col class="align-self-center mt-5 mt-lg-0" cols="12" lg="4">
                                <v-row>
                                    <v-col class="my-md-3 my-lg-10" cols="12">
                                        <h4 :class="sectionH4">Crowdsourcing</h4>
                                        <div :class="sectionDiv" class="mt-1 mt-lg-6">Instantly get access to a scalable workforce of people across the
                                            world</div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="my-md-3 my-lg-10" cols="12">
                                        <h4 :class="sectionH4">Next gen AI</h4>
                                        <div :class="sectionDiv" class="mt-1 mt-lg-6">Easily leverage AI models in your data processing pipelines at
                                            affordable prices</div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="mt-10">
                            <v-col class="text-center">
                                <router-link to="/platform">
                                    <v-btn :class="sectionButton" rounded size="x-large" class="px-6">Get started today</v-btn>
                                </router-link>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row :class="section" class="my-5 my-md-10 w-100 highlight">
                    <v-col>
                        <v-row><v-col>
                                <h2 :class="sectionH2">Spotlight on quality</h2>
                            </v-col></v-row>
                        <v-row class="mb-lg-10"><v-col>
                                <h3 :class="sectionH3">Ensuring the consistent quality of processed data is paramount to our platform</h3>
                            </v-col></v-row>
                        <v-row>
                            <v-col>
                                <h4  :class="sectionH4">Metrics</h4>
                                <div :class="sectionDiv" class="justified mt-1 mt-lg-6">The performance of each agent is constantly assessed for
                                    ensuring the quality of data processing, and transparently stored as on-chain proofs.
                                </div>
                            </v-col>
                            <v-col class="flex-grow-0 px-16" v-if="$vuetify.breakpoint.lgAndUp">
                                <div style="width: 1px; background: white; height: 100%"></div>
                            </v-col>
                            <v-col cols="12" v-else>
                                <hr />
                            </v-col>
                            <v-col>
                                <h4 :class="sectionH4">Gamification</h4>
                                <div :class="sectionDiv" class="justified mt-1 mt-lg-6">The platform implements incentives for ensuring that our agents
                                    are responsible, engaged and are constantly seeking to improve their performance.</div>
                            </v-col>
                            <v-col class="flex-grow-0 px-16" v-if="$vuetify.breakpoint.lgAndUp">
                                <div style="width: 1px; background: white; height: 100%"></div>
                            </v-col>
                            <v-col cols="12" v-else>
                                <hr />
                            </v-col>
                            <v-col>
                                <h4 :class="sectionH4">Trustlessness</h4>
                                <div :class="sectionDiv" class="justified mt-1 mt-lg-6">No agent is considered trusted, whether human or AI. The ground
                                    truth in data processing is determined through replication and consensus.</div>
                            </v-col>
                        </v-row>
                        <v-row class="mt-10">
                            <v-col class="text-center">
                                <a href="https://docs.timeworx.io" target="_blank">
                                    <v-btn :class="sectionButton" rounded size="x-large" class="px-6">Learn more from our whitepaper</v-btn>
                                </a>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row :class="section" class="my-5 my-md-10 w-100 highlight">
                    <v-row class="w-100"><v-col>
                            <h2 :class="sectionH2">Build next gen AI</h2>
                        </v-col></v-row>
                    <v-row class="mb-lg-10"><v-col>
                            <h3 :class="sectionH3">Make the most of the virtuous cycle between data processing and AI</h3>
                        </v-col></v-row>
                    <v-row class="w-100 my-lg-10">
                        <v-col>
                            <hr />
                        </v-col>
                    </v-row>
                    <v-row :style="{'gap': $vuetify.breakpoint.lgAndUp ? '20px' : '40px;'}" class="pa-3 pa-lg-0">
                        <v-col class="my-lg-10" cols="12" md>
                            <h4 :class="sectionH4">Generate</h4>
                            <div :class="sectionDiv"class="mt-6">Obtain human generated content based on your requirements</div>
                        </v-col>
                        <v-col class="my-lg-10" cols="12" md>
                            <h4 :class="sectionH4">Annotate</h4>
                            <div :class="sectionDiv" class="mt-6">Get your data labeled by a diversified global workforce</div>
                        </v-col>
                        <v-col class="my-lg-10" cols="12" md>
                            <h4 :class="sectionH4">Evaluate</h4>
                            <div :class="sectionDiv" class="mt-6">Apply human feedback in your active learning processes</div>
                        </v-col>
                        <v-col class="my-lg-10" cols="12" md>
                            <h4 :class="sectionH4">Monetize</h4>
                            <div :class="sectionDiv" class="mt-6">Deploy your ML models directly in the platform and earn rewards</div>
                        </v-col>
                    </v-row>
                    <v-row class="mt-md-10">
                        <v-col class="text-center">
                            <a href="https://calendar.app.google/Kg1gxLhKBUTWMLpGA" target="_blank">
                                <v-btn :class="sectionButton" rounded size="x-large" class="px-6">Set up a meeting with our experts</v-btn>
                            </a>
                        </v-col>
                    </v-row>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import Page from "../Page.vue";
import TextQuote from "../TextQuote.vue";
import WidgetWrapper from "../WidgetWrapper.vue";
import PartnersDark from "../PartnersDark.vue";

export default {
    name: "HomeV2",
    metaInfo: {
        title: "Home"
    },
    components: { Page, TextQuote, WidgetWrapper, PartnersDark },
    props: [
        "titleShadow",
        "title",
        "topContent",
        "middleContent",
        "picContent",
        "ourMission",
        "timeworksIsOnAMi",
        "ourVision",
        "weEnvisionTimework",
        "whoIsTheWeBehi",
        "whoWeAre",
        "aboutUs",
        "teamTitle",
        "team",
        "advisorsTitle",
        "advisors",
        "quote",
        "missionVisionBgImg",
        "missionVisionBgImgLazy",
        "staking",
    ],
    computed: {
        heading() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "heading heading-desktop poppins-bold-white-80px";
                case "sm":
                case "xs":
                    return "heading heading-mobile poppins-bold-white-24px";
            }
        },
        subheading() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "subheading subheading-desktop poppins-normal-white-24px";
                case "sm":
                case "xs":
                    return "subheading subheading-mobile poppins-normal-white-12px";
            }
        },
        mission() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "text-center poppins-normal-white-34px";
                case "sm":
                case "xs":
                    return "text-center poppins-normal-white-16px";
            }
        },
        cardHeadline() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "text-center poppins-bold-white-40px";
                case "sm":
                case "xs":
                    return "text-center poppins-bold-white-24px";
            }
        },
        cardBody() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "text-center poppins-normal-white-20px";
                case "sm":
                case "xs":
                    return "text-center poppins-normal-white-12px";
            }
        },
        cardButton() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "button poppins-semi-bold-white-20px";
                case "sm":
                case "xs":
                    return "button poppins-semi-bold-14px";
            }
        },
        description() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "text-center poppins-normal-white-34px";
                case "sm":
                case "xs":
                    return "text-center poppins-normal-white-16px";
            }
        },
        background() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "background-desktop";
                case "sm":
                case "xs":
                    return "background-mobile";
            }
        },
        top() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "top-desktop";
                case "sm":
                case "xs":
                    return "top-mobile";
            }
        },
        content() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                    return "content-container content-container-xl";
                case "lg":
                    return "content-container content-container-lg";
                case "md":
                    return "content-container content-container-md";
                case "sm":
                    return "content-container content-container-sm";
                case "xs":
                    return "content-container content-container-xs";
            }
        },
        injLogoHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "";
                case "sm":
                case "xs":
                    return "18px";
            }
        },
        mvxLogoHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "";
                case "sm":
                case "xs":
                    return "12px";
            }
        },
        section() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "section section-desktop";
                case "sm":
                case "xs":
                    return "section section-mobile";
            }
        },
        sectionH2() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "poppins-bold-white-42px";
                case "sm":
                case "xs":
                    return "poppins-bold-white-24px";
            }
        },
        sectionH3() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "poppins-normal-white-20px";
                case "sm":
                case "xs":
                    return "poppins-normal-white-12px";
            }
        },
        sectionH4() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "poppins-bold-white-20px";
                case "sm":
                case "xs":
                    return "poppins-bold-white-16px";
            }
        },
        sectionDiv() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "poppins-normal-white-16px";
                case "sm":
                case "xs":
                    return "poppins-normal-white-12px";
            }
        },
        sectionButton() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "button poppins-semi-bold-white-14px";
                case "sm":
                case "xs":
                    return "button poppins-semi-bold-white-14px";
            }
        },
    },
};
</script>

<style scoped>
.background-desktop {
    background: linear-gradient(180deg, #310293 0%, #100032 10%, #000000 20%);
}

.background-mobile {
    background: linear-gradient(180deg, #310293 -0.5%, #100032 2.5%, #000000 7%);
}

.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1480px;
    margin: auto;
}

.content-container {
    margin: auto;
    max-width: 2160px;
}

.content-container-xl {
    background: url('~@/assets/img/home/bg_glow.png') no-repeat center;
    background-size: contain;
    background-position-y: 200px;
}

.content-container-lg {
    background: url('~@/assets/img/home/bg_glow.png') no-repeat center;
    background-size: contain;
    background-position-y: 200px;
    margin-left: -20%;
    padding-left: 20%;
    margin-right: 20%;
    padding-right: 20%;
    width: 140%;
    max-width: 140%;
}

.content-container-md {
    background: url('~@/assets/img/home/bg_glow_mobile.png') no-repeat center;
    background-size: 100vw;
    max-width: 100vw;
    background-position-y: initial;
    margin-left: -10%;
    padding-left: 10%;
    margin-right: 10%;
    padding-right: 10%;
    width: 120%;
    max-width: 120%;
}

.content-container-sm {
    background: url('~@/assets/img/home/bg_glow_mobile.png') no-repeat center;
    background-size: contain;
    background-position-y: initial;
}

.content-container-xs {
    background: url('~@/assets/img/home/bg_glow_mobile.png') no-repeat center;
    background-position-y: 300px;
    margin-left: -10%;
    padding-left: 10%;
    margin-right: 10%;
    padding-right: 10%;
    width: 120%;
}

.top-desktop {
    background: url('~@/assets/img/home/top.png') no-repeat center;
    background-size: cover;
    width: 2560px;
    padding-top: 140px;
    padding-bottom: 100px;
}

.top-mobile {
    background: url('~@/assets/img/home/top.png') no-repeat center;
    background-size: contain;
    background-position: center;
    width: 776px;
    padding: 80px 0px;
    margin-bottom: -40px;
}

.partners {
    max-width: 1480px;
    overflow: hidden;
}

.heading {
    text-align: center;
}

.heading-desktop {
    line-height: 96px;
}

.heading-mobile {
    line-height: 36px;
}

.heading-span {
    background: linear-gradient(270deg, #D655FF 0%, #FF5800 100%);
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.subheading {
    text-align: center;
}

.subheading-desktop {
    line-height: 36px;
}

.subheading-mobile {
    line-height: 18px;
}

.subheading-desktop>div {
    display: inline-block;
}

.subheading-mobile>div {
    display: inline-block;
}

.subheading>span {
    display: inline-block;
}

.subheading-desktop > a > .logo-inj {
    margin-bottom: -6px;
}

.subheading-desktop > a > .logo-mvx {
    margin-bottom: 0px;
}

.subheading-mobile > a > .logo-inj {
    margin-bottom: -4px;
    height: 16px;
}

.subheading-mobile > a > .logo-mvx {
    margin-bottom: 0px;
    height: 11px;
}

.mission {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: white;
}

.highlight {
    border: 1px solid #3B3165;
    border-radius: 24px;
    background: #00000099;
}

.community-card {
    margin-top: 3px;
    margin-bottom: 3px;
    cursor: pointer;
}

.community-card:hover {
    margin-top: 0px;
    margin-bottom: 0px;
    border: 4px solid #FF5800;
    border-radius: 24px;
    box-shadow: 0px 0px 80px 0px #FF580044;
}

.community-card>div>button {
    background-color: #FF5800 !important;
    color: white !important;
}

.business-card {
    margin-top: 3px;
    margin-bottom: 3px;
    cursor: pointer;
}

.business-card:hover {
    margin-top: 0px;
    margin-bottom: 0px;
    border: 4px solid #9417F2;
    border-radius: 24px;
    box-shadow: 0px 0px 80px 0px #9417F244;
}

.business-card>div>button {
    background-color: #9417F2 !important;
    color: white !important;
}

.button {
    text-align: center;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
}

.cta {
    text-transform: unset !important;
}

.separator {
    border: 0px;
    height: 2px;
    background: linear-gradient(90deg, #3B3165 20%, #CBBEFF 50%, #3B3165 80%);
}

.section-desktop {
    padding: 80px 160px;
}

.section-mobile {
    padding: 40px 20px;
}

.section>>>h2 {
    text-align: left;
    color: white;
}

.section>>>h3 {
    text-align: left;
}

.section>>>h4 {
    text-align: left;
    color: #FF5800;
    text-transform: uppercase;
}

.section>>>div {
    text-align: left;
    color: white;
}

.section>>>button {
    background-color: #FF5800 !important;
    text-align: center;
    color: white;
    text-transform: unset !important;
}

.w-100 {
    width: 100%;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

.justified {
    text-align: justify !important;
    text-justify: inter-word !important;
}
</style>