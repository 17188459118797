import { defineStore } from 'pinia'

export const useScrollStore = defineStore('scroll', {
  state: () => ({
    scrollable: true,
    requestTopClosed: false,
  }),
  actions: {
    setScrollable(scrollable) {
      this.scrollable = scrollable;
    },
    closeTop() {
      this.requestTopClosed = true;
    },
    onTopClosed() {
      this.requestTopClosed = false;
    },
  }
})