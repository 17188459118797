<template>
  <v-app v-bind:style="appStyle">
    <router-view />
    <FooterDark v-bind="footerData" />
    <NavBar
      :isTranslucent="!this.scrolled"
      :twitter="footerData.socialProps.twitter"
      :telegram="footerData.socialProps.telegram"
      :discord="footerData.socialProps.discord"
      style="position: fixed; top: 0; left: 0; right: 0; z-index: 6"
    />
    <v-img
      v-if="showBackToTop"
      :src="require('@/assets/img/back-to-top.svg')"
      style="position: fixed; bottom: 5%; right: 5%; z-index: 5; cursor: pointer;"
      @click="onBackToTopClicked"
    ></v-img>
  </v-app>
</template>

<script>
import { mapActions, mapState, mapStores } from "pinia";
import NavBar from "./components/NavBar.vue";
import { useScrollStore } from "@/stores/scroll";
import { footerData } from "./data";
import Footer from "./components/Footer.vue";
import FooterDark from "./components/FooterDark.vue";

export default {
  name: "App",
  components: {
    NavBar,
    Footer,
    FooterDark,
  },
  props: ["HomeData"],
  data() {
    return {
      scrolled: false,
      appStyle: {
        height: "calc(var(--vh, 1vh) * 100) !important;",
        position: "",
        background: "black"
      },
      footerData: footerData,
    };
  },
  metaInfo() {
    return {
      title: 'Timeworx.io | Make your time work for you',
      titleTemplate:'%s | Timeworx.io'
    }
  },
  computed: {
    ...mapStores(useScrollStore),
    ...mapState(useScrollStore, ["scrollable"]),
    showBackToTop() {
      return this.scrolled && !this.$isMobile();
    },
  },
  methods: {
    ...mapActions(useScrollStore, ["setScrollable"]),
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      this.scrolled = this.scrollable && currentScrollPosition > 0;
    },
    onBackToTopClicked() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    const store = useScrollStore();
    store.$subscribe((mutation, state) => {
      this.appStyle = {
        height: "calc(var(--vh, 1vh) * 100) !important;",
        position: state.scrollable ? "" : "fixed",
      };
    });

    this.setScrollable(true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
