<template>
  <v-container
    class="business flex-column"
    :style="{ paddingLeft: `${peoplePadding}px`, paddingRight: `${peoplePadding}px` }"
  >
    <v-row no-gutters id="top-text-business" class="mt-10">
      <v-col align="center">
        <h1
          :class="[titleCaptionFont, 'd-none', 'd-md-inline']"
          style="opacity: 0.05; white-space: nowrap; overflow: hidden"
        >
          What’s it all about
        </h1>
        <div
          :class="[titleFont, 'mt-12', 'mt-md-0']"
          style="position: relative; top: -25%"
        >
          {{ peopleTitle11Props.whatAreTheTasksT }}
        </div>
      </v-col>
    </v-row>

    <v-row justify="center" align="end" class="ma-0">
      <v-col
        :cols="pictogramNumColumns"
        id="image-processing"
        align="center"
        justify="space-between"
      >
        <div
          :class="[normalTextFont, 'px-0', 'px-sm-8']"
          :style="{ 'text-align': 'center' }"
        >
          {{
            explainVideo4Props.explainWhatsHappeningInAVideoProps
              .explainWhatsHappeningInAVideo
          }}
        </div>
        <lottie :options="imageProcessingOptions" class="px-sm-10 px-lg-0" />
      </v-col>
      <v-col
        :cols="pictogramNumColumns"
        id="text-analysing"
        align="center"
        justify="space-between"
      >
        <div
          :class="[normalTextFont, 'px-0', 'px-sm-8']"
          :style="{ 'text-align': 'center' }"
        >
          {{
            explainVideo3Props.explainWhatsHappeningInAVideoProps
              .explainWhatsHappeningInAVideo
          }}
        </div>
        <lottie :options="textAnalysingOptions" class="px-sm-10 px-lg-0" />
      </v-col>
      <v-col
        :cols="pictogramNumColumns"
        id="x-video2"
        align="center"
        justify="space-between"
      >
        <div
          :class="[normalTextFont, 'px-0', 'px-sm-8']"
          :style="{ 'text-align': 'center' }"
        >
          {{
            explainVideo2Props.explainWhatsHappeningInAVideoProps
              .explainWhatsHappeningInAVideo
          }}
        </div>
        <lottie :options="videoOptions" class="px-sm-10 px-lg-0" />
      </v-col>
      <v-col
        :cols="pictogramNumColumns"
        id="augmentation"
        align="center"
        justify="space-between"
      >
        <div
          :class="[normalTextFont, 'px-0', 'px-sm-8']"
          :style="{ 'text-align': 'center' }"
        >
          {{
            explainVideo1Props.explainWhatsHappeningInAVideoProps
              .explainWhatsHappeningInAVideo
          }}
        </div>
        <lottie :options="augmentationOptions" class="px-sm-10 px-lg-0" />
      </v-col>
    </v-row>

    <v-row no-gutters id="bottom-title" class="mt-sm-8 mt-lg-8">
      <v-col>
        <v-row justify="center">
          <v-col cols="10">
            <div :class="normalTextFont" style="text-align: center">
              {{ peopleTitle12Props.andManyMore }}
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="10">
            <div :class="titleFont" style="text-align: center">
              {{ peopleTitle12Props.whatAreTheTasksT }}
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-left: 8px; margin-right: 8px" justify="center">
          <v-col :cols="bottomTextNumColumns" class="pa-lg-6">
            <div
              :class="normalTextFont"
              :style="{ 'text-align': 'center', 'line-height': bottomLineHeight }"
            >
              {{
                peopleText11Props.explainWhatsHappeningInAVideoProps
                  .explainWhatsHappeningInAVideo
              }}
            </div>
          </v-col>
          <v-col :cols="bottomTextNumColumns" class="pa-lg-6">
            <div
              :class="normalTextFont"
              :style="{ 'text-align': 'center', 'line-height': bottomLineHeight }"
            >
              {{
                peopleText12Props.explainWhatsHappeningInAVideoProps
                  .explainWhatsHappeningInAVideo
              }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gsap from "gsap";
import Lottie from "vue-lottie";
import imageProcessingData from "@/assets/business/1-image-processing.json";
import textAnalysingData from "@/assets/business/2-text-analysing.json";
import videoData from "@/assets/business/3-video.json";
import augmentationData from "@/assets/business/4-media-augmentation.json";

export default {
  name: "Business",
  components: {
    Lottie,
  },
  data() {
    return {
      imageProcessingOptions: { animationData: imageProcessingData, autoplay: true },
      textAnalysingOptions: { animationData: textAnalysingData, autoplay: true },
      videoOptions: { animationData: videoData, autoplay: true },
      augmentationOptions: { animationData: augmentationData, autoplay: true },
    };
  },
  computed: {
    peoplePadding() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.earthGap / 2 + 25;
        case "sm":
        case "md":
          return this.earthGap / 2 + 50;
        default:
          return this.earthGap / 2 + 100;
      }
    },
    bottomLineHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "30px";
        default:
          return "20px";
      }
    },
    normalTextFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return "poppins-normal-white-20px";
        case "lg":
          return "poppins-normal-white-18px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-18px";
          } else {
            return "poppins-normal-white-16px";
          }
        case "xs":
          return "poppins-normal-white-16px";
      }
    },
    bottomTextNumColumns() {
      if (this.$isMobile()) {
        return 12;
      } else {
        switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
            return 6;
          case "md":
            return 5;
          case "xs":
          case "sm":
            return 4;
        }
      }
    },
    titleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-50px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-40px";
          } else {
            return "poppins-bold-white-25px";
          }
        case "xs":
          return "poppins-bold-white-25px";
      }
    },
    titleCaptionFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return "poppins-light-white-130px";
        case "lg":
          return "poppins-light-white-110px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-light-white-70px";
          } else {
            return "poppins-light-white-45px";
          }
        case "xs":
          return "poppins-light-white-45px";
      }
    },
    pictogramNumColumns() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return 3;
        case "md":
        case "sm":
        case "xs":
          return 6;
      }
    },
  },
  props: [
    "peopleTitle11Props",
    "peopleText11Props",
    "peopleText12Props",
    "peopleTitle12Props",
    "explainVideo1Props",
    "augmentationProps",
    "explainVideo2Props",
    "xVideo2Props",
    "explainVideo3Props",
    "textAnalysingProps",
    "explainVideo4Props",
    "imageProcessingProps",
    "earthGap",
  ],
  mounted() {
    gsap.from("#top-text-business", { y: "-400px", duration: 1 });

    gsap.from("#image-processing", { x: "600px", opacity: 0, duration: 1 });
    gsap.from("#text-analysing", { x: "500px", opacity: 0, duration: 1 });
    gsap.from("#x-video2", { x: "400px", opacity: 0, duration: 1 });
    gsap.from("#augmentation", { x: "300px", opacity: 0, duration: 1 });

    gsap.from("#bottom-title", { y: "400px", opacity: 0, duration: 1 });
  },
};
</script>

<style>
.business {
  max-width: 1920px;
  width: 100vw;
  min-height: calc(var(--vh, 1vh) * 100);
}
</style>
