<template>
  <v-container :class="[`text-quote animate-enter5` || ``]" show-on-scroll>
    <v-row justify="start" class="mx-md-16 py-3 py-md-12 mt-10 mt-md-0 ml-n4 mr-n16">
      <v-col cols="2" align="end">
        <div class="quote-mark-left" :class="[$vuetify.breakpoint.smAndDown ? 'quote-mark-left-sm-and-down' : 'quote-mark-left-md-and-up']">“</div>
      </v-col>
      <v-col cols="8" :class="quoteTextFont" style="line-height: 27px">
        <div>{{ quote }}</div>
        <div class="mt-4">
          <img class="quote-author-line" :src="require('@/assets/img/line-78@1x.png')" />
          <span :class="quoteAuthorFont" class="quote-author">{{ author }}</span>
        </div>
      </v-col>
      <div class="quote-mark-right" :class="[$vuetify.breakpoint.smAndDown ? 'quote-mark-right-sm-and-down' : 'quote-mark-right-md-and-up']">“</div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TextQuote",
  props: {
    quote: String,
    author: String,
    expanded: Boolean,
  },
  computed: {
    quoteTextFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-normal-white-18px";
        default:
          return "poppins-normal-white-15px";
      }
    },
    quoteAuthorFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-bold-cyan--aqua-19px";
        default:
          return "poppins-bold-cyan--aqua-17px";
      }
    },
  },
};
</script>

<style>

.text-quote {
  position: relative;
  transform: translate(25px, 0);
  text-align: justify;
  text-justify: inter-word;
}

.text-quote.animate-enter5 {
  animation: animate-enter5-frames 0.7s ease 0.7s 1 normal forwards;
  opacity: 0;
  transform: translate(25px, 0);
}

.quote-author-line {
  object-fit: cover;
  width: 54px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.quote-author {
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: -6px;
  margin-right: -2px;
  margin-top: 4px;
}

.quote-mark-left {
  color: var(--spray);
  font-family: var(--font-family-biz_udpgothic);
  font-style: normal;
  font-weight: 700;
}

.quote-mark-left-sm-and-down {
  font-size: 100px;
  letter-spacing: 0;
  line-height: 100px;
  white-space: nowrap;
}

.quote-mark-left-md-and-up {
  font-size: 169px;
  letter-spacing: 0;
  line-height: 169px;
  white-space: nowrap;
}

.quote-mark-right {
  color: #18002E45;
  font-family: var(--font-family-biz_udpgothic);
  font-style: normal;
  font-weight: 700;
  position: absolute;
  right: 120px;
  top: -30px;
  z-index: -1;
}

.quote-mark-right-sm-and-down {
  letter-spacing: 0;
  font-size: 360px;
  line-height: 460px;
  white-space: nowrap;
  right: 0px;
  top: -20px;
}

.quote-mark-right-md-and-up {
  letter-spacing: 0;
  font-size: 460px;
  line-height: 560px;
  white-space: nowrap;
  right: 220px;
  top: -50px;
}

.text-quote.text-quote-1.animate-enter12 {
  animation: animate-enter12-frames 0.7s ease 0.7s 1 normal forwards;
  opacity: 0;
  transform: translate(25px, 0);
}

@keyframes animate-enter5-frames {
  from {
    opacity: 0;
    transform: translate(25px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes animate-enter12-frames {
  from {
    opacity: 0;
    transform: translate(25px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
</style>
