<template>
  <v-card class="elevation-2 infobox-bg" max-width="500">
    <v-card-title class="infobox-title-bg">
      <v-img :src="icon" max-width="40" max-height="40" contain class="ml-4 mr-8"> </v-img>
      <div :class="titleFont" class="infobox-title my-2">
        {{ title }}
      </div>
    </v-card-title>
    <v-card-text class="py-7 px-10">
      <div class="poppins-normal-white-18px infobox-text">
        {{ text }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "InfoBox",
  props: ["icon", "title", "text"],
  computed: {
    titleFont() {
        switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
            return "poppins-bold-32px";
        default:
          return "poppins-bold-28px";
      }
    }
  }
};
</script>

<style>
.infobox-bg {
  background: #310293 0% 0% no-repeat padding-box !important;
  border-radius: 15px !important;
}

.infobox-title-bg {
  background: #3c01b7 0% 0% no-repeat padding-box !important;
  border-radius: 15px;
}

.infobox-title {
  color: #00ffff;
  text-align: left;
}

.infobox-text {
  text-align: left;
  line-height: 27px;
}
</style>
