<template>
  <Page class="faq-bg">
    <div :class="this.$vuetify.breakpoint.mdAndUp ? 'faq-header' : 'faq-header-mobile'" class="mt-n16 mt-md-n8"></div>
    <div class="faq-body px-4 px-md-0">
      <v-expansion-panels v-for="section in sections" class="faq-sections my-10" flat>
        <div :class=sectionHeaderFont>{{ section.title }}</div>
        <v-expansion-panel v-for="question in section.questions" class="faq-section">
          <v-expansion-panel-header :class=questionTitleFont class="faq-section-question" color="#290180">
            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
            {{ question.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content :class=questionAnswerFont class="faq-section-answer" color="#290180">
            <div v-html=question.answer></div></v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </Page>
</template>

<script>
import Page from "./Page.vue";
import { getFirestore, collection, getDocs, orderBy } from "firebase/firestore";

export default {
  name: "FAQ",
  metaInfo: {
    title: "FAQ"
  },
  components: { Page },
  props: [],
  data() {
    return {
      sections: [],
    };
  },
  async mounted() {
    const querySnapshot = await getDocs(collection(getFirestore(), "faq"));
    const loadedSections = []
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      loadedSections.push({
        title: data.title,
        order: data.order,
        questions: data.questions.map((question) => {
          return {
            title: question.title,
            answer: question.answer,
          }
        }),
      });
    });
    this.sections = loadedSections.sort((a, b) => a.order - b.order);
  },
  computed: {
    sectionHeaderFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "faq-section-header";
        case "sm":
        case "xs":
          return "faq-section-header-mobile";
      }
    },
    questionTitleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-normal-white-22px";
        case "sm":
        case "xs":
          return "poppins-normal-white-18px";
      }
    },
    questionAnswerFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-normal-white-18px";
        case "sm":
        case "xs":
          return "poppins-normal-white-16px";
      }
    },
  },
};
</script>

<style scoped>
.faq-bg {
  background: #310293;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.faq-header {
  background: url('~@/assets/img/faq/header.png') no-repeat center;
  background-size: contain;
  height: 250px;
  width: 100%;
}

.faq-header-mobile {
  background: url('~@/assets/img/faq/header.png') no-repeat center;
  background-size: contain;
  height: 148px;
  width: 100%;
}

.faq-body {
  background-color: #290180;
  width: 100%;
  min-height: 300px;
}

.faq-sections {
  max-width: 1200px;
  margin: auto;
}

.faq-section {
  border-bottom: thin solid #CBBEFF;
  padding-bottom: 20px;
}

.faq-section-header {
  color: #9417F2;
  font-family: var(--font-family-poppins);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  border: 0;
}

.faq-section-header-mobile {
  color: #9417F2;
  font-family: var(--font-family-poppins);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  border: 0;
}

.faq-section-question {
  padding-left: 0px;
  padding-right: 0px;
  font-weight: 500;
  border: 0;
}

.faq-section-answer {
  color: #C9C4D0;
  border: 0;
}

.v-expansion-panels>>>.v-expansion-panel {
  background-color: #290180;
}

.v-expansion-panels > *:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active {
  border-radius: 0;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--next-active {
  border-radius: 0;
}

.v-expansion-panel--active>>>.v-expansion-panel-header {
  color: #957DFB;
}

.v-expansion-panel-content>>>.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-expansion-panel-content>>>a {
  color: #C9C4D0;
}
</style>
