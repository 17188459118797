<template>
  <div class="container-center-horizontal">
    <div class="x1-web-1920-hp-4">
      <div class="overlap-group11">
        <top-page v-bind="topPageFullProps" @showingSide="showingSide($event)" @openAlphaRegistration="openAlphaRegistrationForm()" />
      </div>
      <div v-if="canScroll">
        <Partners />
        <AgentFlow ref="agent" v-if="showingPeople" v-bind="agentFullProps" />
        <BusinessFlow v-if="!showingPeople" v-bind="$props.businessProps" />

      </div>
    </div>
  </div>
</template>

<script>
import TopPage from "./TopPage.vue";
import { useScrollStore } from "@/stores/scroll";
import { mapActions } from "pinia";
import AgentFlow from "./AgentFlow.vue";
import BusinessFlow from "./BusinessFlow.vue";
import Partners from "./Partners.vue";

export default {
  name: "Home",
  components: {
    TopPage,
    AgentFlow,
    BusinessFlow,
    Partners
},
  props: [
    "partnersLogo1",
    "partnersLogo2",
    "partnersLogo3",
    "spanText1",
    "spanText2",
    "partnersLogo4",
    "partnersLogo5",
    "spanText3",
    "spanText4",
    "topPageProps",
    "homeBadgesProps",
    "agentProps",
    "businessProps",
    "agentExpanded",
    "businessExpanded",
    "displayAlphaRegistration",
  ],
  data() {
    return {
      canScroll: false,
      showingPeople: true,
      topPageFullProps: {
        ...this.topPageProps,
        agentPreExpanded: this.agentExpanded,
        businessPreExpanded: this.businessExpanded,
      },
      agentFullProps: {
        ...this.agentProps,
        displayAlphaRegistration: this.displayAlphaRegistration
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      // This is received from router
      let scrollable = this.businessExpanded == true || this.agentExpanded == true;

      this.setScrollable(scrollable);

      if (this.businessExpanded == true) {
        this.showingPeople = false;
      }
    });
  },
  beforeDestroy() {
    this.setScrollable(true);
  },
  methods: {
    ...mapActions(useScrollStore, ["setScrollable"]),

    showingSide({ showing, people }) {
      this.canScroll = showing;
      this.showingPeople = people;
      this.setScrollable(this.canScroll);
    },
    openAlphaRegistrationForm() {
      this.$refs.agent.openAlphaRegistrationForm();
    },
  },
  computed: {
  },
};
</script>

<style>
.x1-web-1920-hp-4 {
  background-color: black !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  align-items: center;
  width: 100%;
}

.overlap-group11 {
  z-index: 5;
  width: 100vw;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: calc(var(--vh, 1vh) * 100);
}
</style>
