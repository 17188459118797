<template>
  <v-form class="get-in-touch-form mt-2" ref="form" v-model="valid" lazy-validation>
    <v-container class="px-10">
      <v-row no-gutters justify="center" class="mb-8 mb-md-11">
        <v-col>
          <div :class="titleFont" style="text-align: center;">{{title}}</div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="6" lg="5" xl="4">
          <v-text-field
            label="Name"
            :background-color="'white'"
            filled
            rounded
            :rules="nameRules"
            required
            v-model="name"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="5" xl="4">
          <v-text-field
            label="Email Address"
            :background-color="'white'"
            filled
            rounded
            :rules="emailRules"
            required
            v-model="email"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="hasRadioGroup" no-gutters justify="center">
        <v-col cols="12" sm="9" md="12" lg="10" xl="8" align="center">
          <v-radio-group v-model="entityType">
            <v-radio class="radio-bg-white mb-4" value="agent" color="#ff6200 !important" background-color="white">
              <template v-slot:label>
                <div :class="radioFont">I want to monetize my time</div>
              </template>
            </v-radio>
            <v-radio class="radio-bg-white" value="business" color="#ff6200 !important">
              <template v-slot:label>
                <div :class="radioFont">I need help building something awesome</div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center" v-if="entityType == 'business'">
        <v-col cols="12" sm="9" md="12" lg="10" xl="8">
          <v-textarea
            filled
            rounded
            :background-color="'white'"
            label="Message"
            :rules="messageRules"
            v-model="message"
        ></v-textarea>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center">
        <v-col cols="12" sm="9" md="12" lg="10" xl="8">
          <v-checkbox
              color="#CBBEFF"
              v-model="allowMarketing"
              :label="marketing"
              class="get-in-touch-marketing-checkbox mt-7"
            />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center">
          <Button v-if="!loading" :text="'Get in touch'" @click="submit" />
          <v-progress-circular
            indeterminate
            color="#ff6200"
            v-if="loading"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="#ff6200"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
      </v-snackbar>
    </v-container>
  </v-form>
</template>

<script>
import { httpsCallable } from '@firebase/functions';
import { functions } from '../firebase/config';
import Button from './Button.vue';
import { mapWritableState } from "pinia";
import { useGetInTouchStore } from "@/stores/getintouch";
const config = require("@/config");

export default {
  name: "GetInTouch",
  components: {
    Button,
  },
  props: [
    "successMessage",
    "errorMessage",
    "marketing"
  ],
  data() {
    return {
      name: "",
      email: "",
      message: "",
      allowMarketing: false,
      valid: true,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      nameRules: [
        v => !!v || 'Name is required',
      ],
      messageRules: [
        v => !!v || this.entityType == 'agent' || 'Message is required',
      ],
      snackbar: false,
      snackbarMessage: '',
      loading: false,
    };
  },
  computed: {
    ...mapWritableState(useGetInTouchStore, ['title', 'hasRadioGroup', 'entityType']),
    titleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-50px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-31px";
          } else {
            return "poppins-bold-white-25px";
          }
        case "xs":
          return "poppins-bold-white-25px";
      }
    },
    radioFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-19px";
        default:
          return "poppins-normal-white-15px";
      }
    },
  },
  methods: {
    submit () {
      this.$refs.form.validate();
      setTimeout(() => {
        if (this.valid) {
          this.loading = true;
          const getInTouch = httpsCallable(functions, config.functions.GET_IN_TOUCH);
          getInTouch({
            name: this.name,
            email: this.email,
            message: this.message,
            marketing: this.allowMarketing,
          })
          .then((result) => {
            this.snackbarMessage = this.successMessage;
            this.snackbar = true;
            this.loading = false;

            this.$gtm.trackEvent({
              event: 'subscribe-nwl',
              category: 'Subscribe',
              action: 'click',
              label: 'Subscribe to nwl',
              value: 0,
              noninteraction: false, // Optional
            });
          })
          .catch((error) => {
            this.snackbarMessage = this.errorMessage;
            this.snackbar = true;
            console.log(error);
            this.loading = false;
          });
        }
      });
    },
  }
};
</script>

<style>
.radio-bg-white .v-icon {
  color: white !important;
}

.get-in-touch-form {
  width: 100vw;
  max-width: 1920px;
}

.get-in-touch-marketing-checkbox .v-label {
  color: white;
  font-family: var(--font-family-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.get-in-touch-marketing-checkbox .v-icon {
  color: white !important;
}

::v-deep .get-in-touch-marketing-checkbox .v-label {
  color: #CBBEFF;
  font-family: var(--font-family-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
</style>
