<template>
  <div class="widget-wrapper">
    <div class="widget-wrapper-inner">
      <img
        class="x1000_f_347430802_n-s"
        :src="require('@/assets/img/aboutus/cover.png')"
      />
  </div>
  </div>
</template>

<script>
export default {
  name: "WidgetWrapper",
  mounted: () => {
    var image = document.querySelectorAll(".widget-wrapper-inner");
    new window.simpleParallax(image, {
      orientation: "up",
      scale: 1.2,
      overflow: false,
      delay: 0.4,
      transition: "none",
    });
  },
};
</script>

<style>
.widget-wrapper,
.widget-wrapper-1 {
  align-items: flex-center;
  display: flex;
  min-height: 100%;
  min-width: 100%;
}

.widget-wrapper-inner,
.widget-wrapper-inner-1 {
  background: #080293 url('~@/assets/img/aboutus/cover.png');
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  min-height: 100%;
  min-width: 100%;
  scale: 1.2;
}

.x1000_f_347430802_n-s,
.x1000_f_347430802_n-s-1 {
  min-height: 100%;
  min-width: 100%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  object-fit: cover;
}
</style>
