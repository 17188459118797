<template>
  <div class="d-flex flex-column">
    <div
      v-for="(badge, index) in badges"
      class="badge-container my-lg-3 my-2"
      @mouseover="onMouseOver(index)"
      @mouseleave="onMouseLeave(index)"
      v-bind:style="containerStyle"
    >
      <div v-if="glowing[index]" class="glow" v-bind:style="glowStyle" />
      <div class="badge-bg d-flex justify-center align-center" v-bind:style="bgStyle">
        <img class="badge-icon" :src="badge.imgSrc" v-bind:style="iconStyle" />
      </div>
      <span :class="['badge-label', labelFont]" v-bind:style="labelStyle">{{
        badge.label
      }}</span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "IconBadges",
  props: ["badges"],
  data() {
    return {
      glowing: [],
      containerStyle: {},
      glowStyle: {},
      bgStyle: {},
      iconStyle: {},
      labelStyle: {},
    };
  },
  computed: {
    labelFont() {
        if (this.$isMobile()) {
            return 'poppins-normal-white-10px';
        } else {
            return 'poppins-normal-white-16px';
        }
    }
  },
  created() {
    for (let i = 0; i < this.badges.length; i++) {
      this.glowing[i] = false;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      const baseContSize = [90, 100];
      const baseGlowBadgeSize = [68, 69];
      const baseIconSize = [50, 45];
      const baseLabelSize = [90, 76];

      let mul = 1;
      if (this.$isMobile()) {
        mul = 0.7;
      }

      let ml = (baseContSize[0] - baseGlowBadgeSize[0]) / 2;

      this.containerStyle = {
        width: `${baseContSize[0] * mul}px`,
        height: `${baseContSize[1] * mul}px`,
      };

      this.glowStyle = {
        width: `${baseGlowBadgeSize[0] * mul}px`,
        height: `${baseGlowBadgeSize[1] * mul}px`,
        marginLeft: `${ml * mul}px`,
      };

      this.bgStyle = {
        width: `${baseGlowBadgeSize[0] * mul}px`,
        height: `${baseGlowBadgeSize[1] * mul}px`,
        marginLeft: `${ml * mul}px`,
      };

      this.iconStyle = {
        width: `${baseIconSize[0] * mul}px`,
        height: `${baseIconSize[1] * mul}px`,
      };

      this.labelStyle = {
        width: `${baseLabelSize[0] * mul}px`,
        top: `${baseLabelSize[1] * mul}px`,
      };
    },
    onMouseOver(index) {
      Vue.set(this.glowing, index, true);
    },
    onMouseLeave(index) {
      Vue.set(this.glowing, index, false);
    },
  },
};
</script>

<style>
.badge-container {
  position: relative;
}

.glow {
  position: absolute;
  background: #00ffff 0% 0% no-repeat padding-box;
  opacity: 1;
  filter: blur(30px);
}

.badge-bg {
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 50%;
  opacity: 1;
}

.badge-icon {
  opacity: 1;
}

.badge-label {
  position: absolute;
  text-align: center;
}
</style>
