<template>
  <div class="mt-md-10">
    <v-row>
      <v-col>
        <a :href="email" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/mail.svg')" contain :max-height="maxIconSize"
            :max-width="maxIconSize"></v-img>
        </a>
      </v-col>
      <v-col>
        <a :href="twitter" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/twitter.svg')" contain :max-height="maxIconSize"
            :max-width="maxIconSize"></v-img>
        </a>
      </v-col>
      <v-col>
        <a :href="telegram" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/telegram.svg')" contain :max-height="maxIconSize"
            :max-width="maxIconSize"></v-img>
        </a>
      </v-col>
      <v-col>
        <a :href="discord" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/discord.svg')" contain :max-height="maxIconSize"
            :max-width="maxIconSize"></v-img>
        </a>
      </v-col>
      <v-col>
        <a :href="linkedin" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/linkedin.svg')" contain :max-height="maxIconSize"
            :max-width="maxIconSize"></v-img>
        </a>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp">
        <a :href="medium" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/medium.svg')" contain :max-height="maxIconSize"
            :max-width="maxIconSize" class="mx-auto"></v-img>
        </a>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp">
        <a :href="reddit" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/reddit.svg')" contain :max-height="maxIconSize"
            :max-width="maxIconSize" class="mx-auto"></v-img>
        </a>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp">
        <a :href="galxe" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/galxe.svg')" contain :max-height="maxIconSize"
            :max-width="maxIconSize" class="mx-auto"></v-img>
        </a>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp">
        <a :href="instagram" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/instagram.svg')" contain :max-height="maxIconSize"
            :max-width="maxIconSize" class="mx-auto"></v-img>
        </a>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp">
        <a :href="tiktok" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/tiktok.svg')" contain :max-height="maxIconSize"
            :max-width="maxIconSize" class="mx-auto"></v-img>
        </a>
      </v-col>
    </v-row>
    <v-row class="mt-md-10" v-if="$vuetify.breakpoint.smAndDown">
      <v-spacer />
      <v-col>
        <a :href="medium" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/medium.svg')" contain :max-height="maxIconSize"
            :max-width="maxIconSize" class="mx-auto"></v-img>
        </a>
      </v-col>
      <v-col>
        <a :href="reddit" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/reddit.svg')" contain :max-height="maxIconSize"
            :max-width="maxIconSize" class="mx-auto"></v-img>
        </a>
      </v-col>
      <v-col>
        <a :href="galxe" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/galxe.svg')" contain :max-height="maxIconSize"
            :max-width="maxIconSize" class="mx-auto"></v-img>
        </a>
      </v-col>
      <v-col>
        <a :href="instagram" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/instagram.svg')" contain :max-height="maxIconSize"
            :max-width="maxIconSize" class="mx-auto"></v-img>
        </a>
      </v-col>
      <v-col>
        <a :href="tiktok" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/tiktok.svg')" contain :max-height="maxIconSize"
            :max-width="maxIconSize" class="mx-auto"></v-img>
        </a>
      </v-col>
      <v-spacer />
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SocialMediaIcons",
  props: ["email", "twitter", "telegram", "discord", "linkedin", "medium", "reddit", "galxe", "instagram", "tiktok"],
  computed: {
    maxIconSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
        case "xs":
          return 40;
        default:
          return 60;
      }
    },
  },
};
</script>

<style></style>
