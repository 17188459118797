<template>
  <div
    v-if="visible"
    :class="$isMobile() ? 'partners-bg-mobile' : 'partners-bg'"
  >
    <dynamic-marquee
      direction="row"
      :reverse="true"
    >
      <div
          class="d-flex"
          :style="$isMobile() ? 'height: 20px;' : 'height: 40px;'"
      >
        <img
        	v-for="(partner, index) in partners"
        	:key="index"
            :src="partner.logo"
            class="mx-5 mx-md-10"
            @click="navigate(partner.url)"
            style="cursor: pointer;"
        >
        </img>
      </div>
    </dynamic-marquee>
  </div>
</template>

<script>
import { getFirestore, collection, getDocs } from "firebase/firestore";
import DynamicMarquee from 'vue-dynamic-marquee';

export default {
  name: "PartnersDark",
  components: {
    DynamicMarquee
  },
  props: [],
  data() {
    return {
      partners: [],
      visible: false
    };
  },
  async mounted() {
    this.runQuery();
  },
  methods: {
    navigate(link) {
      window.open(link, '_blank', 'noreferrer');
    },
    async runQuery() {
      const querySnapshot = await getDocs(collection(getFirestore(), "partners"));

      let partners = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        partners.unshift({
            logo: data.logo_dark,
            name: data.name,
            url: data.url,
            idx: data.idx,
          });
      });
      
      this.partners = partners.sort((p1, p2) => p1.idx - p2.idx);
      this.visible = true;
    },
  }
};
</script>

<style scoped>
.partners-bg {
  background-color: #000000;
  height: 80px;
  max-width: 1920px;
  width: 100vw;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.partners-bg-mobile {
  background-color: #000000;
  height: 60px;
  max-width: 1920px;
  width: 100vw;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
</style>
