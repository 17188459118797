<template>
    <svg class="cp-svg" viewBox="0 0 300 300" xlink="http://www.w3.org/1999/xlink">
      <circle class="cp-outer-data" cx="150" cy="150" r="110" stroke="#3b00a0" />
      <circle class="cp-data" cx="150" cy="150" r="110" stroke="#00ffff" stroke-linecap="round" />
      <text id="cp-text" class="poppins-normal-50px" stroke="#00ffff" fill="#00ffff" y="170" x="150" text-anchor="middle">
        {{curVal}}%
      </text>
    </svg>
</template>

<script>
import gsap from "gsap";

export default {
  name: "CircularProgress",
  components: {},
  props: ["value"],
  data() {
    return {
      curVal: 0,
    };
  },
  mounted() {
    gsap.set("#cp-text", { autoAlpha: 1 });
    gsap.set(".cp-data", { rotation: -90, transformOrigin: "center", drawSVG: "0" });
    this.animate();
  },
  methods: {
    animate() {
      let duration = 2;
      let ease = "Power2.easeInOut";

      gsap.to(".cp-data", { drawSVG: `${this.value}%`, duration: duration, ease: ease });
      let target = { val: this.curVal };
      gsap.to(target, { duration: duration, ease: ease, val: this.value, onUpdate: () => {
        this.curVal = target.val.toFixed(0);
      }});
    },
  },
  watch: {
    value(newValue, oldValue) {
      this.animate();
    }
  },
};
</script>

<style>
.cp-svg {
  position: relative;
  height: 100%;
  width: 100%;
}

.cp-outer-data {
  stroke-width: 60;
  fill: none;
}

.cp-data {
  stroke-width: 40;
  fill: none;
}
</style>
