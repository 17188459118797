var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{style:({ 
      'background-color': hover ? '#ff6200' : 'transparent',
      'color': hover ? 'white' : '#ff6200',
      'border-color': '#ff6200',
      'z-index': 2,
      'text-transform': 'unset !important',
      'padding': '0px 35px'
    }),attrs:{"elevation":"3","outlined":"","rounded":""},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('div',{staticClass:"poppins-normal-16px"},[_vm._v(_vm._s(_vm.text))])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }