<template>
  <v-container fluid>
    <v-row class="businessflow-section1-bg" justify="center">
      <v-col lg="8" cols="11" class="mt-10 pt-lg-10">
        <div class="d-flex flex-column">
          <div :class="sectionNumberFont">01</div>
          <div :class="sectionLabelFont">{{ s1.title }}</div>
          <div :class="contentFont" class="mt-7">{{ s1.content }}</div>
          <v-row class="mt-10 pt-lg-10" justify="center">
            <v-col
              lg="4"
              md="6"
              cols="12"
              class="d-flex justify-center"
              v-for="card in s1.cards"
              :key="card.icon"
            >
              <InfoBox :icon="card.icon" :title="card.title" :text="card.text" />
            </v-col>
          </v-row>
          <div class="d-flex my-10 py-lg-10 flex-md-row flex-column align-center">
            <TooltipButton
              :color="'#FA4A4A'"
              :text="s1.button[0]"
              :tooltip="'Coming soon'"
              :tooltipColor="'#E8EAED'"
              :tooltipTextColor="'#3B00A0'"
            />
            <TooltipButton
              :color="'#FFFFFF'"
              :text="s1.button[1]"
              :tooltip="'Coming soon'"
              :tooltipColor="'#E8EAED'"
              :tooltipTextColor="'#3B00A0'"
              :outlined="true"
              class="ml-md-10 mt-md-0 mt-6"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="businessflow-section2-bg" justify="center">
      <v-col lg="8" cols="11" class="mt-10 pt-lg-10">
        <div class="d-flex flex-column">
          <div :class="sectionNumberFont">02</div>
          <div :class="sectionLabelFont">{{ s2.title }}</div>
          <div :class="contentFont" class="mt-7">{{ s2.content }}</div>
          <div class="d-flex my-10 flex-md-row flex-column align-center">
            <TooltipButton
              :color="'#FA4A4A'"
              :text="s2.button"
              :tooltip="'Coming soon'"
              :tooltipColor="'#E8EAED'"
              :tooltipTextColor="'#3B00A0'"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="businessflow-section3-bg" justify="center">
      <v-col lg="8" cols="11" class="mt-4">
        <div class="d-flex flex-column">
          <div :class="sectionNumberFont">03</div>
          <div :class="sectionLabelFont">{{ s3.title }}</div>
          <div :class="contentFont" class="mt-7">{{ s3.content }}</div>
          <div class="d-flex my-10 pb-lg-10 flex-md-row flex-column align-center">
            <TooltipButton
              :color="'#FA4A4A'"
              :text="s3.button"
              :tooltip="'Coming soon'"
              :tooltipColor="'#E8EAED'"
              :tooltipTextColor="'#3B00A0'"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="businessflow-section4-bg" justify="center">
      <v-col lg="8" cols="11" class="mt-10">
        <div class="d-flex flex-column">
          <div :class="sectionNumberFont">04</div>
          <div :class="sectionLabelFont">{{ s4.title }}</div>
          <div :class="contentFont" class="mt-7">{{ s4.content[0] }}</div>

          <div style="position: relative">
            <v-img
              :src="s4.img"
              :lazySrc="s4.lazyImg"
              style="max-width: 90vw"
              contain
              class="mt-10"
              @click="onImageClick(s4.img)"
            ></v-img>
            <v-icon
              v-if="$vuetify.breakpoint.xsOnly"
              style="position: absolute; bottom: 10px; right: 10px"
              >mdi-fullscreen</v-icon
            >
          </div>

          <div :class="contentFont" class="mt-10">{{ s4.content[1] }}</div>

          <div class="d-none d-md-flex flex-column">
            <div
              v-for="(usecase, index) in s4.usecase"
              :key="index"
              class="d-flex flex-column mt-10 pt-lg-10"
            >
              <div :class="usecaseNumberFont" class="">Use case {{ index + 1 }}</div>
              <div :class="usecaseNameFont" class="">{{ usecase.title }}</div>
              <v-img
                :src="usecase.img"
                :lazySrc="usecase.lazyImg"
                class="mt-10"
                style="max-width: 90vw"
                @click="onImageClick(usecase.img)"
              ></v-img>
            </div>
          </div>

          <div class="d-flex d-md-none mx-n7 mt-8">
            <v-slide-group>
              <v-slide-item v-for="(usecase, index) in s4.usecase" :key="index">
                <div
                  class="d-flex flex-column ml-7"
                  :class="{
                    'mr-10': index == s4.usecase.length - 1,
                    'pr-4': index < s4.usecase.length - 1,
                  }"
                >
                  <div :class="usecaseNumberFont" class="">Use case {{ index + 1 }}</div>
                  <div :class="usecaseNameFont" style="white-space: normal !important">
                    {{ usecase.title }}
                  </div>
                  <div style="position: relative">
                    <v-img
                      :src="usecase.img"
                      :lazySrc="usecase.lazyImg"
                      max-width="75vw"
                      class="mt-6"
                      contain
                      @click="onImageClick(usecase.img)"
                    ></v-img>
                    <v-icon
                      v-if="$vuetify.breakpoint.xsOnly"
                      style="position: absolute; bottom: 10px; right: 10px"
                      >mdi-fullscreen</v-icon
                    >
                  </div>
                </div>
              </v-slide-item>
            </v-slide-group>
          </div>

          <div class="d-flex my-10 pb-lg-10 flex-md-row flex-column align-center">
            <TooltipButton
              :color="'#FA4A4A'"
              :text="s4.button"
              :tooltip="'Coming soon'"
              :tooltipColor="'#E8EAED'"
              :tooltipTextColor="'#3B00A0'"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="businessflow-section5-bg" justify="center">
      <v-col lg="8" cols="11" class="mt-10 pt-lg-10">
        <div class="d-flex flex-column">
          <div :class="sectionNumberFont">05</div>
          <div :class="sectionLabelFont">{{ s5.title }}</div>

          <v-row class="d-none d-md-flex mt-10">
            <v-col class="d-flex flex-column">
              <div :class="contentFont">{{ s5.content[0] }}</div>
              <div :class="contentFont" class="mt-6">{{ s5.content[1] }}</div>
            </v-col>
            <v-col cols="1" class="d-flex justify-center mx-n6">
              <div style="border: 2px solid #ffffff"></div>
            </v-col>
            <v-col class="d-flex flex-column">
              <div :class="contentFont">{{ s5.content[2] }}</div>
              <div :class="contentFont" class="mt-6">{{ s5.content[3] }}</div>
            </v-col>
          </v-row>

          <div class="d-flex d-md-none flex-column mt-10">
            <div :class="contentFont">{{ s5.content[0] }}</div>
            <div :class="contentFont" class="mt-6">{{ s5.content[1] }}</div>
            <div :class="contentFont" class="mt-6">{{ s5.content[2] }}</div>
            <div :class="contentFont" class="mt-6">{{ s5.content[3] }}</div>
          </div>

          <div class="d-flex mt-8 mb-16 flex-md-row flex-column align-center">
            <TooltipButton
              :color="'#FA4A4A'"
              :text="s5.button"
              :tooltip="'Coming soon'"
              :tooltipColor="'#E8EAED'"
              :tooltipTextColor="'#3B00A0'"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="businessflow-section6-bg" justify="center">
      <v-col lg="8" cols="11" class="mt-10 pt-lg-10 mb-10 mb-lg-10">
        <div class="d-flex flex-column">
          <div :class="sectionNumberFont">06</div>
          <div :class="sectionLabelFont">{{ s6.title }}</div>
          <v-row>
            <v-col md="5" cols="12">
              <div :class="contentFont" class="mt-7">{{ s6.content[0] }}</div>
              <div :class="contentFont" class="mt-7">{{ s6.content[1] }}</div>
            </v-col>
            <v-col md="1" cols="0"></v-col>
            <v-col md="5" cols="12" class="d-flex justify-center align-center py-md-10">
              <v-img
                :src="s6.img"
                :lazySrc="s6.lazyImg"
                contain
                class="pa-6"
                :style="$vuetify.breakpoint.smAndDown ? 'max-width: 90vw' : ''"
              ></v-img>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="imgOverlay" transition="dialog-bottom-transition" fullscreen>
      <div style="position: relative; width: 100%; height: 100%">
        <v-zoomer
          ref="zoomer"
          style="width: 100%; height: 100%"
          :zoomed.sync="imgOverlay"
          class="businessflow-zoomer-bg"
        >
          <img :src="imgSrc" style="object-fit: contain; width: 100%; height: 100%" />
        </v-zoomer>
        <v-icon
          color="#ff6200"
          x-large
          style="position: absolute; top: 5%; right: 10%"
          @click="onImageClosed"
          >mdi-close</v-icon
        >
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import InfoBox from "./InfoBox.vue";
import { mapActions } from "pinia";
import { useGetInTouchStore } from "@/stores/getintouch";
import TooltipButton from "./TooltipButton.vue";

export default {
  name: "BusinessFlow",
  props: ["s1", "s2", "s3", "s4", "s5", "s6"],
  data() {
    return {
      imgOverlay: false,
      imgSrc: null,
    };
  },
  created() {
    this.onBusinessPage();
  },
  destroyed() {
    this.onGenericPage();
  },
  computed: {
    sectionNumberFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-35px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-35px";
          } else {
            return "poppins-normal-white-35px";
          }
        case "xs":
          return "poppins-normal-white-35px";
      }
    },
    sectionLabelFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-55px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-50px";
          } else {
            return "poppins-bold-white-40px";
          }
        case "xs":
          return "poppins-bold-white-40px";
      }
    },
    usecaseNumberFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-35px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-35px";
          } else {
            return "poppins-normal-white-25px";
          }
        case "xs":
          return "poppins-normal-white-25px";
      }
    },
    usecaseNameFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-45px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-45px";
          } else {
            return "poppins-bold-white-35px";
          }
        case "xs":
          return "poppins-bold-white-35px";
      }
    },
    contentFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-18px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-18px";
          } else {
            return "poppins-normal-white-18px";
          }
        case "xs":
          return "poppins-normal-white-18px";
      }
    },
  },
  methods: {
    ...mapActions(useGetInTouchStore, ["onBusinessPage", "onGenericPage"]),

    onImageClick(img) {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.imgSrc = img;
        this.imgOverlay = true;
      }
    },

    onImageClosed() {
      this.imgOverlay = false;
      // this.$refs.zoomer.zoomOut();
    },
  },
  components: { InfoBox, TooltipButton },
};
</script>

<style>
.businessflow-section1-bg {
  background: #3b00a0 0% 0% no-repeat padding-box;
  max-width: 1920px;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.businessflow-section2-bg {
  background: #9417f2 0% 0% no-repeat padding-box;
  max-width: 1920px;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.businessflow-section3-bg {
  background: #9417f2 0% 0% no-repeat padding-box;
  max-width: 1920px;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.businessflow-section4-bg {
  background: #4f10bf 0% 0% no-repeat padding-box;
  max-width: 1920px;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.businessflow-section5-bg {
  background: #310293 0% 0% no-repeat padding-box;
  max-width: 1920px;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.businessflow-section6-bg {
  background: #9417f2 0% 0% no-repeat padding-box;
  max-width: 1920px;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.businessflow-zoomer-bg {
  background: rgb(0 0 0 / 0.65) !important;
}
</style>
