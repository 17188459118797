<template>
  <Page class="link-tree-top-bg">
    <h1 :class="titleFont" class="mt-n10 mt-md-10" style="text-align: center">Your place to learn and discover about
      Timeworx.io</h1>

    <h2 :class="subtitleFont" class="mt-5 mt-md-10">Follow the project</h2>

    <div ref="social_links" style="overflow-x: auto; display: flex; width: 100%;" class="pb-2">
      <a :href="homepage" target="_blank" rel="noopener noreferrer" class="ml-auto">
        <v-img :src="require('@/assets/img/links/timeworx.svg')" contain class="mx-3 mb-1" />
        <div class="poppins-semi-bold-white-12px link-tree-link">timeworx.io</div>
      </a>
      <a :href="userjourney" target="_blank" rel="noopener noreferrer">
        <v-img :src="require('@/assets/img/links/userjourney.svg')" contain class="mx-3 mb-1" />
        <div class="poppins-semi-bold-white-12px link-tree-link">User<br />Journey</div>
      </a>
      <a :href="twitter" target="_blank" rel="noopener noreferrer">
        <v-img :src="require('@/assets/img/links/twitter.svg')" contain class="mx-3 mb-1" />
        <div class="poppins-semi-bold-white-12px link-tree-link">Twitter</div>
      </a>
      <a :href="telegram" target="_blank" rel="noopener noreferrer">
        <v-img :src="require('@/assets/img/links/telegram.svg')" contain class="mx-3 mb-1" />
        <div class="poppins-semi-bold-white-12px link-tree-link">Telegram</div>
      </a>
      <a :href="discord" target="_blank" rel="noopener noreferrer">
        <v-img :src="require('@/assets/img/links/discord.svg')" contain class="mx-3 mb-1" />
        <div class="poppins-semi-bold-white-12px link-tree-link">Discord</div>
      </a>
      <a :href="galxe" target="_blank" rel="noopener noreferrer">
        <v-img :src="require('@/assets/img/links/galxe.svg')" contain class="mx-3 mb-1" />
        <div class="poppins-semi-bold-white-12px link-tree-link">Galxe</div>
      </a>
      <a :href="linkedin" target="_blank" rel="noopener noreferrer">
        <v-img :src="require('@/assets/img/links/linkedin.svg')" contain class="mx-3 mb-1" />
        <div class="poppins-semi-bold-white-12px link-tree-link">LinkedIn</div>
      </a>
      <a :href="instagram" target="_blank" rel="noopener noreferrer">
        <v-img :src="require('@/assets/img/links/instagram.svg')" contain class="mx-3 mb-1" />
        <div class="poppins-semi-bold-white-12px link-tree-link">Instagram</div>
      </a>
      <a :href="tiktok" target="_blank" rel="noopener noreferrer" class="mr-auto">
        <v-img :src="require('@/assets/img/links/telegram.svg')" contain class="mx-3 mb-1" />
        <div class="poppins-semi-bold-white-12px link-tree-link">TikTok</div>
      </a>
    </div>

    <a href="https://docs.timeworx.io" target="_blank">
      <v-btn elevation="3" rounded class="mt-3 mt-md-8" :style="{
      'background-color': '#ff6200',
      'color': 'white',
      'z-index': 2,
      'text-transform': 'unset !important',
      'padding': '0px 35px'
    }">
      <div class="poppins-normal-16px">Explore our Whitepaper</div>
    </v-btn>
    </a>

    <v-btn elevation="3" rounded v-on:click="tokenomics" class="mt-3 mt-md-8" :style="{
      'background-color': '#ff6200',
      'color': 'white',
      'z-index': 2,
      'text-transform': 'unset !important',
      'padding': '0px 35px'
    }">
      <div class="poppins-normal-16px">Discover our Tokenomics</div>
    </v-btn>

    <h2 :class="subtitleFont" class="mt-3 mt-md-8">Test the app</h2>

    <v-btn elevation="3" rounded v-on:click="register" :style="{
      'background-color': '#ff6200',
      'color': 'white',
      'z-index': 2,
      'text-transform': 'unset !important',
      'padding': '0px 35px'
    }">
      <div class="poppins-normal-16px">Register for alpha testing</div>
    </v-btn>

    <h2 :class="subtitleFont" class="mt-3 mt-md-8">Injective Grant DAO Quadratic Funding Round Demo Day</h2>

    <a href="https://youtube.com/live/ERglSJaYbZg" target="_blank">
      <v-btn rounded v-bind="attrs" :style="{
          'background-color': '#ff6200',
          'color': 'white',
          'z-index': 2,
          'text-transform': 'unset !important',
          'padding': '0px 35px',
          'width': '300px'
          }">
          <div class="poppins-normal-16px">Youtube</div>
      </v-btn>
    </a>
    <a href="https://www.binance.com/en/live/video?roomId=2186315" target="_blank" class="my-3">
      <v-btn color="#ff6200" rounded v-bind="attrs" :style="{
          'background-color': '#ff6200',
          'color': 'white',
          'z-index': 2,
          'text-transform': 'unset !important',
          'padding': '0px 35px',
          'width': '300px'
          }">
          <div class="poppins-normal-16px">Binance Live</div>
      </v-btn>
    </a>

    <h2 :class="[subtitleFont, 'mt-5 mt-md-10']">Read More</h2>

    <div v-for="article in articles" class="mb-4 mx-2">
      <v-btn elevation="3" rounded v-on:click="navigate(article.link)" :style="{
        'background-color': 'white',
        'color': '#ff6200',
        'text-transform': 'unset !important',
        'padding': '20px 0px',
        'width': $vuetify.breakpoint.mdAndUp ? '600px' : '95vw'
      }">
        <v-layout row fill-width grow>
          <v-flex style="flex:0; margin-left: 20px">
            <v-icon>{{ article.icon }}</v-icon>
          </v-flex>
          <v-flex style="max-width: 80vw;" class="poppins-semi-bold-blue-gem-14px ml-2 text-truncate" :style="{
            'text-align': $vuetify.breakpoint.mdAndUp ? 'center' : 'start',
          }">
            {{ article.name }}
          </v-flex>
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
        </v-layout>
      </v-btn>
    </div>
  </Page>
</template>

<script>
import Page from "./Page.vue";
import { getFirestore, collection, getDocs } from "firebase/firestore";

export default {
  name: "LinkTree",
  metaInfo: {
    title: "Link Tree"
  },
  components: { Page },
  props: ["homepage", "userjourney", "twitter", "telegram", "discord", "galxe", "linkedin", "instagram", "tiktok", "egldcommunity"],
  data() {
    return {
      articles: [],
    };
  },
  async mounted() {
    const querySnapshot = await getDocs(collection(getFirestore(), "linktree"));
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      this.articles.unshift({
        name: data.name,
        link: data.link,
        icon: data.icon,
      });
    });
  },
  computed: {
    contentFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-18px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-16px";
          } else {
            return "poppins-normal-white-15px";
          }
        case "xs":
          return "poppins-normal-white-15px";
      }
    },
    titleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-22px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-normal-white-18px";
      }
    },
    subtitleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-semi-bold-white-18px mb-4";
        case "md":
        case "sm":
        case "xs":
          return "poppins-semi-bold-white-18px mb-2";
      }
    },
  },
  methods: {
    register() {
      let route = this.$router.resolve({ path: '/alpha' });
      window.open(route.href, '_blank').catch((err) => { });
    },
    tokenomics() {
      let route = this.$router.resolve({ path: '/tokenomics' });
      window.open(route.href, '_blank').catch((err) => { });
    },
    navigate(link) {
      window.open(link, '_blank', 'noreferrer');
    }
  }
};
</script>

<style scoped>
.link-tree-top-bg {
  background: transparent linear-gradient(105.38deg, #B154FF 0%, #3B00A0 100%) 0% 0% no-repeat padding-box;
}

.link-tree-link {
  text-align: center;
}

a {
  text-decoration: none;
}
</style>
