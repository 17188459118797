<template>
    <v-row class="article-entry flex-nowrap mx-0 pt-8 pb-7" v-if="$vuetify.breakpoint.lgAndUp">
        <v-col class="article-info flex-grow-1 flex-shrink-0 align-self-center">
            <v-row :class="headerFont" class="article-header">
                <a :href="article.link" class="article-link" target="_blank" rel="noopener noreferrer">
                    {{ `${article.date} &centerdot; ${article.time} min read` }}
                </a>
            </v-row>
            <v-row :class="titleFont" class="text-truncate mt-8">
                <a :href="article.link" class="article-link" target="_blank" rel="noopener noreferrer">
                    {{ article.title }}
                </a>
            </v-row>
            <v-row :class="introFont" class="article-intro max-three-lines mt-4 pr-2">
                <a :href="article.link" class="article-link" target="_blank" rel="noopener noreferrer">
                    {{ article.intro }}
                </a>
            </v-row>
            <v-row class="mt-12 mb-4">
                <v-chip outlined color="#34323A" text-color="#B09EFF" style="border-color: #B09EFF"
                    :class="labelFont" class="article-label mr-3" v-for="label in article.labels">
                    {{ label }}
                </v-chip>
            </v-row>
        </v-col>
        <v-col class="article-image flex-grow-0 flex-shrink-1">
            <a :href="article.link" class="article-link" target="_blank" rel="noopener noreferrer">
                <img :src="article.featuredImage" class="article-image-src" />
            </a>
            <div v-if="article.featured" class="featured poppins-semi-bold-black-16px">
                <img :src="require('@/assets/img/new_releases.svg')" />
                Featured
            </div>
        </v-col>
    </v-row>
    <v-container class="article-entry mx-0 mt-2" v-else>
        <v-row :class="headerFont" class="article-header">
            <a :href="article.link" class="article-link" target="_blank" rel="noopener noreferrer">
                {{ `${article.date} &centerdot; ${article.time} min read` }}
            </a>
        </v-row>
        <v-row class="flex-nowrap mt-5">
            <v-col class="article-info-mobile flex-grow-1 flex-shrink-0 align-self-start">
                <v-row :class="titleFont" class="max-three-lines mt-n2" style="line-height: 132%;">
                    <a :href="article.link" class="article-link" target="_blank" rel="noopener noreferrer">
                        {{ article.title }}
                    </a>
                </v-row>
            </v-col>
            <v-col class="article-image-mobile flex-grow-0 flex-shrink-1">
                <a :href="article.link" class="article-link" target="_blank" rel="noopener noreferrer">
                    <img :src="article.featuredImage" class="article-image-src-mobile" />
                </a>
                <div v-if="article.featured" class="featured-mobile poppins-semi-bold-black-16px">
                    <img :src="require('@/assets/img/new_releases.svg')" />
                </div>
            </v-col>
        </v-row>
        <v-row :class="introFont" class="article-intro max-two-lines mt-4">
            <a :href="article.link" class="article-link" target="_blank" rel="noopener noreferrer">
                {{ article.intro }}
            </a>
        </v-row>
        <v-row class="mt-6 mb-2">
            <v-chip outlined color="#34323A" text-color="#B09EFF" style="border-color: #B09EFF;"
                class="article-label mr-3 mt-1" v-for="label in article.labels"
            >
                <span :class="labelFont">{{ label }}</span>
            </v-chip>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "ArticleListItem",
    props: ["article"],
    computed: {
        headerFont() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                    return "poppins-normal-14px";
                case "md":
                case "sm":
                case "xs":
                    return "poppins-normal-10px";
            }
        },
        titleFont() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                    return "poppins-bold-white-24px";
                case "md":
                case "sm":
                case "xs":
                    return "poppins-bold-white-16px";
            }
        },
        introFont() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                    return "poppins-normal-white-16px";
                case "md":
                case "sm":
                case "xs":
                    return "poppins-normal-white-12px";
            }
        },
        labelFont() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                    return "poppins-normal-14px";
                case "md":
                case "sm":
                case "xs":
                    return "poppins-normal-12px";
            }
        },
    },
};
</script>

<style>
.article-entry {
    border-bottom: thin #38363A solid;
}

.article-entry.active {
    border-top: thin #38363A solid;
    padding-top: 20px;
}

.article-info {
    min-width: 100px;
    max-width: 100%;
    height: 240px;
}

.article-mobile {
    min-width: 100px;
    max-width: 100%;
    height: 64px;
}

.article-header {
    color: #AAAAAA;
}

.article-intro {
    line-height: 180%;
}

.article-image {
    position: relative;
    padding: 0;
    margin: 0;
    height: 240px;
}

.article-image-mobile {
    position: relative;
    padding: 0;
    margin: 0;
    height: 64px;
}

.article-image-src {
    height: 240px;
    width: 360px;
    object-fit: cover;
}

.article-image-src-mobile {
    height: 64px;
    width: 96px;
    object-fit: cover;
}

.max-two-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.max-three-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.featured {
    position: absolute;
    padding: 8px;
    vertical-align: middle;
    color: #151515;
    background: #00DDDD;
    border-radius: 12px 12px 0px 0px;
    bottom: 0px;
    right: 24px;
}

.featured-mobile {
    position: absolute;
    color: #151515;
    background: #00DDDD;
    border-radius: 8px 8px 0px 0px;
    bottom: 0px;
    right: 8px;
}

.featured>img {
    width: 24px;
    height: 24px;
    margin-left: 4px;
    margin-right: 4px;
    vertical-align: middle;
}

.featured-mobile>img {
    width: 14px;
    height: 14px;
    margin-left: 6px;
    margin-right: 6px;
    vertical-align: middle;
}

a.article-link,
a.article-link:hover,
a.article-link:visited,
a.article-link:active {
    color: inherit !important;
    text-decoration: none !important;
}

.article-label.v-chip.v-chip--outlined.v-chip.v-chip {
    background: #34323A !important;
}

.article-label.v-chip.v-chip--outlined.v-chip.v-chip--active {
    background: #E6DEFF !important;
    color: #310293 !important;
}
</style>