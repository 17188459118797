<template>
  <v-container fluid>
    <v-dialog
      width="unset"
      v-model="alphaPopup"
    >
      <alpha-registration v-bind="$props.alpha" @close="alphaPopup = false" />
    </v-dialog>
    <v-row class="agent-section1-bg" justify="center">
      <v-col cols="12" class="d-flex d-lg-none flex-column align-end mb-10 px-lg-0 px-12">
        <div :class="sectionNumberFont">01</div>
        <div :class="sectionLabelFont">{{ s1.label }}</div>
      </v-col>
      <v-col lg="8" cols="12" class="d-flex mb-10 pb-lg-10 px-lg-0 px-12">
        <div class="agent-lg-section-label d-none d-lg-flex flex-column align-end mr-10">
          <div :class="sectionLabelFont">{{ s1.label }}</div>
          <div :class="sectionNumberFont" class="agent-section-label-sm">01</div>
        </div>
        <div class="d-flex flex-column ml-lg-16" style="width: 100%">
          <div :class="titleFont" class="mt-lg-10 pt-lg-10">{{ s1.title }}</div>
          <div :class="contentFont" class="mt-6 mt-lg-10">{{ s1.content }}</div>
          <div class="d-flex mt-5 mb-4 flex-md-row flex-column align-center">
            <v-btn
              elevation="2"
              rounded
              color="#3B00A0"
              style="text-transform: unset !important"
              @click="alphaPopup = true"
              class="px-8"
              ><span class="poppins-normal-white-16px">{{ s1.button[0] }}</span></v-btn
            >
            <TooltipButton
              :color="'#FFFFFF'"
              :text="s1.button[1]"
              :tooltip="'Coming soon'"
              :tooltipColor="'#E8EAED'"
              :tooltipTextColor="'#3B00A0'"
              class="ml-md-10 mt-md-0 mt-5"
              :outlined="true"
            />
          </div>
          <v-img :src="s1.phoneImg" max-width="841" contain></v-img>
        </div>
      </v-col>
      <v-col lg="1" v-if="$vuetify.breakpoint.lgAndUp"></v-col>
    </v-row>
    <v-row class="agent-section2-bg" justify="center">
      <v-col cols="12" class="d-flex d-lg-none flex-column align-end mb-10 px-lg-0 px-12">
        <div :class="sectionNumberFont">02</div>
        <div :class="sectionLabelFont" class="agent-section-label-sm">{{ s2.label }}</div>
      </v-col>
      <v-col lg="8" cols="12" class="d-flex mb-10 pb-lg-10 px-lg-0 px-12">
        <div class="agent-lg-section-label d-none d-lg-flex flex-column align-end mr-10">
          <div :class="sectionLabelFont">{{ s2.label }}</div>
          <div :class="sectionNumberFont">02</div>
        </div>
        <div class="ml-lg-16 mt-lg-10 pt-lg-10">
          <v-row justify="center">
            <v-col lg="6" cols="12">
              <div class="d-flex flex-column">
                <div :class="titleFont">{{ s2.title }}</div>

                <ul class="mt-14 ml-4">
                  <li class="poppins-extrabold-italic-white-18px agent-list-item">
                    {{ s2.questions[0] }}
                  </li>
                  <li class="poppins-extrabold-italic-white-18px agent-list-item mt-4">
                    {{ s2.questions[1] }}
                  </li>
                  <li class="poppins-extrabold-italic-white-18px agent-list-item mt-4">
                    {{ s2.questions[2] }}
                  </li>
                </ul>

                <div :class="contentFont" class="mt-12 mt-lg-10">
                  {{ s2.content[0] }}
                </div>
              </div>
            </v-col>
            <v-col lg="1" cols="0"></v-col>
            <v-col cols="5" class="d-none d-md-flex">
              <v-card color="transparent" class="rounded-xl mb-auto" elevation="24">
                <v-img
                  v-for="(nft, index) in s2.nfts"
                  :key="index"
                  :src="nft.src"
                  :lazySrc="nft.lazySrc"
                  contain
                  class="mb-n1"
                  :max-width="333"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" cols="12" class="d-flex flex-column justify-space-between">
              <div :class="contentFont" class="mt-6 mt-md-10">{{ s2.content[1] }}</div>
              <TooltipButton
                :color="'#3B00A0'"
                :text="s2.button[0]"
                :tooltip="'Coming soon'"
                :tooltipColor="'#E8EAED'"
                :tooltipTextColor="'#3B00A0'"
                class="mt-8 align-self-md-start align-self-center"
              />
            </v-col>
            <v-col md="6" cols="12" class="d-flex flex-column justify-space-between">
              <div :class="contentFont" class="mt-6 mt-md-10">{{ s2.content[2] }}</div>
              <TooltipButton
                :color="'#3B00A0'"
                :text="s2.button[1]"
                :tooltip="'Coming soon'"
                :tooltipColor="'#E8EAED'"
                :tooltipTextColor="'#3B00A0'"
                class="mt-8 align-self-md-start align-self-center"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col lg="1" v-if="$vuetify.breakpoint.lgAndUp"></v-col>
      <v-col cols="12" class="d-md-none d-flex align-center justify-center mb-10 pb-10">
        <v-card color="transparent" class="rounded-xl mx-6" elevation="24">
          <v-img :src="s2.nfts[0].src" contain :max-width="300" class=""></v-img>
        </v-card>
        <!-- <v-slide-group show-arrows class="mb-10 pb-10">
          <v-slide-item v-for="(nft, index) in s2.nfts" :key="index">
            <v-img :src="nft" contain :max-width="300" class="mx-6"></v-img>
          </v-slide-item>
        </v-slide-group> -->
      </v-col>
    </v-row>
    <v-row class="agent-section3-bg pb-16" justify="center">
      <v-col cols="12" class="d-flex d-lg-none flex-column align-end mb-10 px-lg-0 px-12">
        <div :class="sectionNumberFont">03</div>
        <div :class="sectionLabelFont" class="agent-section-label-sm">{{ s3.label }}</div>
      </v-col>
      <v-col lg="8" cols="12" class="d-flex pb-lg-10 px-lg-0 px-12">
        <div class="agent-lg-section-label d-none d-lg-flex flex-column align-end mr-10">
          <div :class="sectionLabelFont">{{ s3.label }}</div>
          <div :class="sectionNumberFont">03</div>
        </div>
        <div class="d-flex ml-lg-16">
          <div class="d-flex flex-column">
            <div :class="contentFont" class="mt-lg-10 pt-lg-10">{{ s3.content[0] }}</div>
            <div :class="titleFont" class="mt-6 mt-lg-10">{{ s3.title }}</div>

            <div class="d-none d-md-flex mt-6">
              <div class="d-flex flex-column my-6">
                <v-img
                  :src="s3.taskExample[0].src"
                  :lazySrc="s3.taskExample[0].lazySrc"
                  :max-height="400"
                  :max-width="240"
                  contain
                ></v-img>
                <div :class="captionFont" class="mt-4">{{ s3.taskExample[0].text }}</div>
              </div>
              <div class="agent-task-ex-divider mx-8"></div>
              <div class="d-flex flex-column my-6">
                <v-img
                  :src="s3.taskExample[1].src"
                  :lazySrc="s3.taskExample[1].lazySrc"
                  :max-height="400"
                  :max-width="240"
                  contain
                ></v-img>
                <div :class="captionFont" class="mt-4">{{ s3.taskExample[1].text }}</div>
              </div>
              <div class="agent-task-ex-divider mx-8"></div>
              <div class="d-flex flex-column my-6">
                <v-img
                  :src="s3.taskExample[2].src"
                  :lazySrc="s3.taskExample[2].lazySrc"
                  :max-height="400"
                  :max-width="240"
                  contain
                ></v-img>
                <div :class="captionFont" class="mt-4">{{ s3.taskExample[2].text }}</div>
              </div>
            </div>

            <!-- I can't do this shit... If I don't constrain the slide group it goes off screen and pulls everything -->
            <div class="d-md-none mt-6 mb-10 ml-n12 mr-n12" style="width: 100vw">
              <v-slide-group>
                <v-slide-item>
                  <div class="d-flex flex-column mx-6">
                    <v-img
                      :src="s3.taskExample[0].src"
                      :lazySrc="s3.taskExample[0].lazySrc"
                      :max-height="500"
                      :max-width="225"
                      contain
                      style="flex: none"
                    ></v-img>
                    <div :class="captionFont" class="agent-task-ex-sm mt-4">
                      {{ s3.taskExample[0].text }}
                    </div>
                  </div>
                </v-slide-item>
                <v-slide-item>
                  <div class="d-flex flex-column mx-6">
                    <v-img
                      :src="s3.taskExample[1].src"
                      :lazySrc="s3.taskExample[1].lazySrc"
                      :max-height="500"
                      :max-width="225"
                      contain
                      style="flex: none"
                    ></v-img>
                    <div :class="captionFont" class="agent-task-ex-sm mt-4">
                      {{ s3.taskExample[1].text }}
                    </div>
                  </div>
                </v-slide-item>
                <v-slide-item>
                  <div class="d-flex flex-column mx-6">
                    <v-img
                      :src="s3.taskExample[2].src"
                      :lazySrc="s3.taskExample[2].lazySrc"
                      :max-height="500"
                      :max-width="225"
                      contain
                      style="flex: none"
                    ></v-img>
                    <div :class="captionFont" class="agent-task-ex-sm mt-4">
                      {{ s3.taskExample[2].text }}
                    </div>
                  </div>
                </v-slide-item>
              </v-slide-group>
            </div>

            <v-row class="mt-6">
              <v-col md="7" cols="12">
                <div :class="titleFont" class="mt-6 mt-lg-10">{{ s3.title2 }}</div>
                <div :class="contentFont" class="mt-6 mt-lg-10">{{ s3.content2[0] }}</div>
                <div :class="contentFont" class="mt-6 mt-lg-10">{{ s3.content2[1] }}</div>
                <div :class="contentFont" class="mt-6 mt-lg-10">{{ s3.content2[2] }}</div>

                <div class="d-flex justify-md-start justify-center mt-6">
                  <TooltipButton
                    :color="'#3B00A0'"
                    :text="s3.button[0]"
                    :tooltip="'Coming soon'"
                    :tooltipColor="'#E8EAED'"
                    :tooltipTextColor="'#3B00A0'"
                  />
                </div>

                <div :class="titleFont" class="mt-8 mt-lg-10">{{ s3.title3 }}</div>
                <div :class="contentFont" class="mt-6 mt-lg-10">{{ s3.content3[0] }}</div>

                <div
                  class="d-flex flex-column flex-md-row justify-md-start justify-center align-center mt-6"
                >
                  <TooltipButton
                    :color="'#3B00A0'"
                    :text="s3.button[1]"
                    :tooltip="'Coming soon'"
                    :tooltipColor="'#E8EAED'"
                    :tooltipTextColor="'#3B00A0'"
                  />
                  <div class="poppins-bold-italic-white-18px mt-md-0 mt-3 ml-md-4 ml-0">
                    and see for yourself.
                  </div>
                </div>
              </v-col>
              <v-col md="5" cols="12" align="center" justify="center">
                <v-img
                  :src="s3.img"
                  :lazySrc="s3.lazyImg"
                  :max-height="650"
                  :max-width="300"
                  contain
                  class="mt-10 mt-lg-16"
                ></v-img>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col lg="1" v-if="$vuetify.breakpoint.lgAndUp"></v-col>
    </v-row>
    <v-row class="agent-section4-bg" justify="center">
      <v-col cols="12" class="d-flex d-lg-none flex-column align-end mb-10 px-lg-0 px-12">
        <div :class="sectionNumberFont">04</div>
        <div :class="sectionLabelFont" class="agent-section-label-sm">{{ s4.label }}</div>
      </v-col>
      <v-col lg="8" cols="12" class="d-flex mb-10 pb-md-10 px-lg-0 px-12">
        <div class="agent-lg-section-label d-none d-lg-flex flex-column align-end mr-10">
          <div :class="sectionLabelFont">{{ s4.label }}</div>
          <div :class="sectionNumberFont">04</div>
        </div>
        <div class="d-flex ml-lg-16">
          <div class="d-flex flex-column">
            <div :class="titleFont" class="mt-lg-10 pt-lg-10">{{ s4.title }}</div>
            <div :class="contentFont" class="mt-6 mt-lg-10">{{ s4.content[0] }}</div>

            <v-row no-gutters class="mt-10 pt-lg-10 mx-sm-0 mx-n12">
              <v-col md="3" cols="6">
                <v-card color="#E558C0" elevation="0" class="rounded-0">
                  <v-responsive :aspect-ratio="1 / 1">
                    <div
                      class="d-flex flex-column justify-space-around"
                      style="height: 100%; width: 100%"
                    >
                      <div :class="titleFont" class="mx-4">{{ s4.stats[0].name }}</div>
                      <div :class="contentSmallerFont" class="mx-4">
                        {{ s4.stats[0].description }}
                      </div>
                    </div>
                  </v-responsive>
                </v-card>
              </v-col>
              <v-col md="3" cols="6">
                <v-card color="#cf44ab" elevation="0" class="rounded-0">
                  <v-responsive :aspect-ratio="1 / 1">
                    <div
                      class="d-flex flex-column justify-space-around"
                      style="height: 100%; width: 100%"
                    >
                      <div :class="titleFont" class="mx-4">{{ s4.stats[1].name }}</div>
                      <div :class="contentSmallerFont" class="mx-4">
                        {{ s4.stats[1].description }}
                      </div>
                    </div>
                  </v-responsive>
                </v-card>
              </v-col>
              <v-col md="3" cols="6">
                <v-card
                  :color="$vuetify.breakpoint.mdAndUp ? '#E558C0' : '#cf44ab'"
                  elevation="0"
                  class="rounded-0"
                >
                  <v-responsive :aspect-ratio="1 / 1">
                    <div
                      class="d-flex flex-column justify-space-around"
                      style="height: 100%; width: 100%"
                    >
                      <div :class="titleFont" class="mx-4">{{ s4.stats[2].name }}</div>
                      <div :class="contentSmallerFont" class="mx-4">
                        {{ s4.stats[2].description }}
                      </div>
                    </div>
                  </v-responsive>
                </v-card>
              </v-col>
              <v-col md="3" cols="6">
                <v-card
                  :color="$vuetify.breakpoint.mdAndUp ? '#cf44ab' : '#E558C0'"
                  elevation="0"
                  class="rounded-0"
                >
                  <v-responsive :aspect-ratio="1 / 1">
                    <div
                      class="d-flex flex-column justify-space-around"
                      style="height: 100%; width: 100%"
                    >
                      <div :class="titleFont" class="mx-4">{{ s4.stats[3].name }}</div>
                      <div :class="contentSmallerFont" class="mx-4">
                        {{ s4.stats[3].description }}
                      </div>
                    </div>
                  </v-responsive>
                </v-card>
              </v-col>
            </v-row>

            <div :class="contentFont" class="mt-10 pt-lg-10">{{ s4.content[1] }}</div>

            <div class="d-flex justify-md-start justify-center mt-md-8 mt-4">
              <TooltipButton
                :color="'#3B00A0'"
                :text="s4.button"
                :tooltip="'Coming soon'"
                :tooltipColor="'#E8EAED'"
                :tooltipTextColor="'#3B00A0'"
              />
            </div>
          </div>
        </div>
      </v-col>
      <v-col lg="1" v-if="$vuetify.breakpoint.lgAndUp"></v-col>
    </v-row>
    <v-row class="agent-section5-bg" justify="center">
      <v-col cols="12" class="d-flex d-lg-none flex-column align-end mb-10 px-lg-0 px-12">
        <div :class="sectionNumberFont">05</div>
        <div :class="sectionLabelFont" class="agent-section-label-sm">{{ s5.label }}</div>
      </v-col>
      <v-col lg="8" cols="12" class="d-flex mb-10 pb-lg-10 px-lg-0 px-12">
        <div class="agent-lg-section-label d-none d-lg-flex flex-column align-end mr-10">
          <div :class="sectionLabelFont">{{ s5.label }}</div>
          <div :class="sectionNumberFont">05</div>
        </div>
        <v-row class="mt-lg-10 pt-lg-10">
          <v-col md="7" cols="12">
            <div class="d-flex ml-lg-16">
              <div class="d-flex flex-column">
                <div :class="contentFont">
                  {{ s5.content[0] }}
                </div>
                <div :class="titleFont" class="mt-6 mt-lg-10">do I claim my TIX?</div>
                <div :class="titleItalicFont" class="">or</div>
                <div :class="titleFont" class="">do I stake my TIX?</div>
                <div :class="contentFont" class="mt-6 mt-lg-10">{{ s5.content[1] }}</div>

                <div
                  class="d-flex justify-md-start justify-center mt-lg-8 mt-md-8 mt-4 mb-4"
                >
                  <TooltipButton
                    :color="'#3B00A0'"
                    :text="s5.button"
                    :tooltip="'Coming soon'"
                    :tooltipColor="'#E8EAED'"
                    :tooltipTextColor="'#3B00A0'"
                  />
                </div>
              </div>
            </div>
          </v-col>
          <v-col md="5" cols="12" class="pb-10" align="center" justify="center">
            <v-img
              :src="s5.img"
              :lazySrc="s5.lazyImg"
              contain
              :max-height="550"
              :max-width="300"
            ></v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="1" v-if="$vuetify.breakpoint.lgAndUp"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "pinia";
import { useGetInTouchStore } from "@/stores/getintouch";
import TooltipButton from "./TooltipButton.vue";
import AlphaRegistration from "./AlphaRegistration.vue";

export default {
  name: "AgentFlow",
  props: ["s1", "s2", "s3", "s4", "s5", "alpha", "displayAlphaRegistration"],
  created() {
    this.onAgentPage();
  },
  destroyed() {
    this.onGenericPage();
  },
  data() {
    return {
      alphaPopup: this.displayAlphaRegistration
    }
  },
  computed: {
    bigTitleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-55px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-39px";
          } else {
            return "poppins-bold-white-39px";
          }
        case "xs":
          return "poppins-bold-white-39px";
      }
    },
    titleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return "poppins-bold-white-28px";
        case "lg":
          return "poppins-bold-white-25px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-25px";
          } else {
            return "poppins-bold-white-25px";
          }
        case "xs":
          return "poppins-bold-white-25px";
      }
    },
    titleItalicFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-italic-white-28px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-italic-white-28px";
          } else {
            return "poppins-italic-white-28px";
          }
        case "xs":
          return "poppins-italic-white-28px";
      }
    },
    contentFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-18px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-18px";
          } else {
            return "poppins-normal-white-18px";
          }
        case "xs":
          return "poppins-normal-white-18px";
      }
    },
    contentSmallerFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-18px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-18px";
          } else {
            return "poppins-normal-white-15px";
          }
        case "xs":
          return "poppins-normal-white-15px";
      }
    },
    captionFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-16px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-16px";
          } else {
            return "poppins-normal-white-16px";
          }
        case "xs":
          return "poppins-normal-white-16px";
      }
    },
    sectionNumberFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-35px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-35px";
          } else {
            return "poppins-normal-white-35px";
          }
        case "xs":
          return "poppins-normal-white-35px";
      }
    },
    sectionLabelFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-55px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-34px";
          } else {
            return "poppins-bold-white-34px";
          }
        case "xs":
          return "poppins-bold-white-30px";
      }
    },
  },
  methods: {
    ...mapActions(useGetInTouchStore, ["onAgentPage", "onGenericPage"]),
    openAlphaRegistrationForm() {
      this.alphaPopup = true;
    }
  },
  components: { TooltipButton, AlphaRegistration },
};
</script>

<style>
.agent-lg-section-label {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  margin-top: 80px;
}

.agent-section1-bg {
  background: #f65843 0% 0% no-repeat padding-box;
  max-width: 1920px;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.agent-section2-bg {
  background: #d12242 0% 0% no-repeat padding-box;
  max-width: 1920px;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.agent-section3-bg {
  background: #f5585a 0% 0% no-repeat padding-box;
  max-width: 1920px;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.agent-section4-bg {
  background: #cf44ab 0% 0% no-repeat padding-box;
  max-width: 1920px;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.agent-section5-bg {
  background: #f65843 0% 0% no-repeat padding-box;
  max-width: 1920px;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.agent-section-label-sm {
  text-align: end;
}

.agent-list-item {
  list-style-type: disc;
}

.agent-task-ex-divider {
  border: 2px solid #ffffff;
}

.agent-task-ex-sm {
  max-width: 250px;
  white-space: normal !important;
}
</style>
