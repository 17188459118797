<template>
  <div class="all overlay-base">
    <div class="left-side" v-bind:style="leftStyle">
      
    </div>
    <div class="right-side" v-bind:style="rightStyle">
      
    </div>
    <people v-if="showingPeople" class="overlay-people" v-bind="$props.peopleProps" :earthGap="sizes.gap" ref="people"
      @openAlphaRegistration="$emit('openAlphaRegistration')" />
    <business v-if="showingBusiness" class="overlay-business" v-bind="$props.businessProps" :earthGap="sizes.gap"
      ref="business" />
  </div>
</template>

<script>
import ExtendPage from "./ExtendPage.vue";
import People from "./toppageoverlays/People.vue";
import gsap from "gsap";
import Business from "./toppageoverlays/Business.vue";
import Lottie from "vue-lottie";
import scrollAnimationData from "@/assets/scrolldown-white.json";
import IconBadges from "./IconBadges.vue";
import { mapActions, mapStores } from "pinia";
import { useScrollStore } from "@/stores/scroll";

const EARTH_COLLAPSE_MULTIPLIER = {
  xs: 0.8,
  sm: 0.6,
  md: 0.4,
  lg: 0.4,
  xl: 0.4,
};

export default {
  name: "TopPage",
  components: {
    ExtendPage,
    People,
    Business,
    Lottie,
    IconBadges,
  },
  props: [
    "titleLeftProps",
    "textLeftProps",
    "componentEarthLeftProps",
    "titleRightProps",
    "textRightProps",
    "componentEarthDigitalRightProps",
    "peopleProps",
    "businessProps",
    "homeBadgesProps",
    "agentPreExpanded",
    "businessPreExpanded",
  ],
  data() {
    return {
      showingLeft: false,
      showingRight: false,
      showingPeople: false,
      showingBusiness: false,
      isTransitioning: false,
      leftEarthImgStyle: {},
      leftPlusStyle: {},
      rightEarthImgStyle: {},
      rightPlusStyle: {},
      sizes: {
        earth: 0,
        earthCollapseMultiplier: 0,
        gap: 0,
      },
      allStyle: {},
      rightStyle: {},
      leftStyle: {},
      badgesStyle: {},
      scrollOptions: { animationData: scrollAnimationData, autoplay: true },
    };
  },
  created() {
    const store = useScrollStore();
    store.$subscribe((mutation, state) => {
      if (state.requestTopClosed) {
        this.onTopClosed();
        if (this.showingLeft) {
            this.animateLeft();
          } else if (this.showingRight) {
            this.animateRight();
          }
      }
    });
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);

      if (this.agentPreExpanded) {
        this.animateLeft();
      }

      if (this.businessPreExpanded) {
        this.animateRight();
      }
    });
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    ...mapStores(useScrollStore),
    normalTextFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-18px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-14px";
          } else {
            return "poppins-normal-white-12px";
          }
        case "xs":
          return "poppins-normal-white-12px";
      }
    },
    bigTextFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-43px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-39px";
          } else {
            return "poppins-bold-white-20px";
          }
        case "xs":
          return "poppins-bold-white-20px";
      }
    },
    scrollIconSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return 100;
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return 75;
          } else {
            return 50;
          }
        case "xs":
          return 50;
      }
    },
  },
  methods: {
    ...mapActions(useScrollStore, ['onTopClosed']),
    onResize() {
      let windowHeight = window.innerHeight;
      let windowWidth = window.innerWidth;
      this.sizes.earth =
        this.$vuetify.breakpoint.name == "xs"
          ? Math.min(windowHeight * 0.9, windowWidth * 0.9)
          : Math.min((windowHeight * 2) / 3, (windowWidth * 2) / 3);

      let top = `${windowHeight / 2 - this.sizes.earth / 2}px`;
      let plusTop = `${windowHeight / 2}px`;
      let plusLateralMultiplier = this.$vuetify.breakpoint.name == "xs" ? 0.425 : 0.475;

      this.leftEarthImgStyle = {
        height: `${this.sizes.earth}px`,
        right: `-${this.sizes.earth / 2}px`,
        top: top,
      };
      this.leftPlusStyle = {
        right: `${this.sizes.earth * plusLateralMultiplier}px`,
        top: plusTop,
        position: "absolute",
      };

      this.rightEarthImgStyle = {
        height: `${this.sizes.earth}px`,
        left: `-${this.sizes.earth / 2}px`,
        top: top,
      };
      this.rightPlusStyle = {
        left: `${this.sizes.earth * plusLateralMultiplier}px`,
        top: plusTop,
        position: "absolute",
      };

      // this will translate into people/business padding
      this.sizes.gap =
        this.sizes.earth *
        (0.9 - EARTH_COLLAPSE_MULTIPLIER[this.$vuetify.breakpoint.name]);

      this.badgesStyle = {
        top: `${windowHeight / 2}px`,
        transform: "translate(50%, -50%)",
      };

      if (this.showingPeople) {
        this.leftStyle = {
          height: `${this.$refs.people.$el.clientHeight}px !important`,
        };
      }
      if (this.showingBusiness) {
        this.rightStyle = {
          height: `${this.$refs.business.$el.clientHeight}px !important`,
        };
      }
    },
    animateLeft(event) {
      if (this.isTransitioning) {
        return;
      }
      this.isTransitioning = true;

      if (!this.showingLeft) {
        this.showingLeft = true;

        gsap.fromTo(
          ".left-side",
          { width: "50%", },
          { width: "100%", duration: 0, delay: 0 }
        );
        gsap.to(".left-container", { opacity: 0, zIndex: -1, duration: 0 });
        gsap.to(".right-container", { opacity: 0, duration: 0 });

        gsap.fromTo(
          ".right-side",
          { width: "50%" },
          { width: "0%", duration: 0, delay: 0 }
        );
        gsap.to(".right-container", { opacity: 0, duration: 0 });

        this.showingPeople = true;
        this.$nextTick(function () {
          this.leftStyle = {
            height: `${this.$refs.people.$el.clientHeight}px !important`,
          };
        });

        this.$emit("showingSide", { showing: true, people: true });
      } else {
        this.showingLeft = false;

        gsap.fromTo(".left-side", { width: "100%" }, { width: "50%", duration: 0 });
        gsap.to(".left-container", { opacity: 1, zIndex: 0, duration: 0, delay: 0 });
        gsap.to(".right-container", { opacity: 1, duration: 0, delay: 0 });

        gsap.fromTo(".right-side", { width: "0%" }, { width: "50%", duration: 0 });
        gsap.to(".right-container", { opacity: 1, duration: textFadeDuration, delay: 0 });

        this.showingPeople = false;
        this.$nextTick(function () {
          this.leftStyle = {
            height: "calc(var(--vh, 1vh) * 100)",
          };
        });

        this.$emit("showingSide", { showing: false, people: false });
      }

      setTimeout(() => {
        this.isTransitioning = false;
      }, maxDuration * 1000);
    },

    animateRight(event) {
      if (this.isTransitioning) {
        return;
      }
      this.isTransitioning = true;

      if (!this.showingRight) {
        this.showingRight = true;

        gsap.fromTo(
          ".right-side",
          { width: "50%" },
          { width: "100%", duration: 0 }
        );
        gsap.to(".right-container", { opacity: 0, duration: 0 });

        gsap.fromTo(
          ".left-side",
          { width: "50%" },
          { width: "0%", duration: 0 }
        );
        gsap.to(".left-container", { opacity: 0, duration: 0 });
        gsap.to(".right-container", { opacity: 0, duration: 0 });

        this.showingBusiness = true;

        this.$nextTick(function () {
          this.rightStyle = {
            height: `${this.$refs.business.$el.clientHeight}px !important`,
          };
        });

        this.$emit("showingSide", { showing: true, people: false });
      } else {
        this.showingRight = false;

        gsap.fromTo(".right-side", { width: "100%" }, { width: "50%", duration: 0 });
        gsap.to(".right-container", { opacity: 1, duration: 0 });

        gsap.fromTo(".left-side", { width: "0%" }, { width: "50%", duration: 0 });
        gsap.to(".left-container", { opacity: 1, duration: 0 });
        gsap.to(".right-container", { opacity: 1, duration: 0 });

        this.showingBusiness = false;
        this.$nextTick(function () {
          this.rightStyle = {
            height: "calc(var(--vh, 1vh) * 100)",
          };
        });

        this.$emit("showingSide", { showing: false, people: true });
      }

      setTimeout(() => {
        this.isTransitioning = false;
      }, maxDuration * 1000);
    },
  },
};
</script>

<style>
.all {
  background-color: black;
  display: flex;
  flex-direction: row;
  max-width: 1920px;
  min-height: calc(var(--vh, 1vh) * 100);
}

.left-side {
  background: linear-gradient(299.34deg, #ff6200 0%, #cf44ab 100%);
  width: 50%;
  position: relative;
  height: calc(var(--vh, 1vh) * 100);
}

.left-container {
  position: relative;
  max-width: 960px !important;
}

.right-container {
  position: relative;
  max-width: 960px !important;
}

.right-side {
  background: linear-gradient(299.34deg, #18002e 0%, #310293 100%);
  width: 50%;
  position: relative;
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100);
}

.overlay-people {
  position: absolute;
  top: 0;
  left: 0;
}

.overlay-business {
  position: absolute;
  top: 0;
  left: 0;
}

.overlay-base {
  position: relative;
  top: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.earth-left {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.earth-left-img {
  position: absolute;
  object-fit: cover;
}

.toppage-plus-icon {
  z-index: 5;
}

.earth-right {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.earth-right-img {
  position: absolute;
  object-fit: cover;
}

.scrollicon-enter-active,
.scrollicon-leave-active {
  transition: opacity 0.5s;
}

.scrollicon-enter,
.scrollicon-leave-to {
  opacity: 0;
}

.badges {
  position: absolute;
  right: 0;
  z-index: 10;
}

.parachute {
  cursor: pointer;
  position: absolute;
  z-index: 1000;
  top: -100%;
  animation-name: floatAnimation;
  animation-duration: 20s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

@keyframes floatAnimation {
    0% {
      left: 50%;
      transform: translate(-50%, -100%);
      top: 0%;
    }
    10% {
      left: 45%;
      transform: rotate(15deg) translate(-50%, 0%);
      top: 10%;
    }
    30% {
      left: 55%;
      transform: rotate(-15deg) translate(-50%, 0%);
      top: 30%;
    }
    50%{
      left: 45%;
      transform: rotate(15deg) translate(-50%, 0%);
      top: 50%;
    }
    70%{
      left: 55%;
      transform: rotate(-15deg) translate(-50%, 0%);
      top: 70%;
    }
    90%{
      left: 45%;
      transform: rotate(15deg) translate(-50%, 0%);
      top: 90%;
    }
    100% {
      left: 50%;
      transform: translate(-50%, 0%);
      top: 100%;
    }
}
</style>
