<template>
  <v-hover v-slot="{ hover }">
    <v-btn
      elevation="3"
      outlined
      rounded
      v-on:click="$emit('click', $event)"
      :style="{ 
        'background-color': hover ? '#ff6200' : 'transparent',
        'color': hover ? 'white' : '#ff6200',
        'border-color': '#ff6200',
        'z-index': 2,
        'text-transform': 'unset !important',
        'padding': '0px 35px'
      }"
    ><div class="poppins-normal-16px">{{ text }}</div></v-btn>
  </v-hover>
</template>

<script>
export default {
  name: "Button",
  props: ["text"],
};
</script>

<style>

</style>
