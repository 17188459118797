<template>
  <div class="d-flex justify-center">
    <div class="cat-container">
      <div v-if="isGlowing" class="cat-glow"></div>
      <v-btn
        elevation="3"
        x-large
        v-on:click="$emit('click', $event)"
        class="rounded-lg cat-btn"
        :style="{
          'background-color': '#310293',
          color: isGlowing ? '#00FFFF' : 'white',
          'z-index': 2,
          'text-transform': 'unset !important',
        }"
        ><span :class="font">{{ item.label }}</span></v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Category",
  components: {},
  props: ["item", "isGlowing"],
  computed: {
    font() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-18px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-normal-14px";
      }
    },
  },
};
</script>

<style>
.cat-container {
  position: relative;
}

.cat-glow {
  background: #00FFFF 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 0.9;
  filter: blur(8px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0px;
}

.cat-btn {
  position: relative;
}
</style>
