<template>
  <v-container class="main-page-container" fluid>
    <Caption
        class="mt-16 mt-md-0"
        :text="title"
        :textClass="titleFont"
        :caption="titleCaption"
        :captionClass="titleCaptionFont"
    />

    <slot />
  </v-container>
</template>

<script>
import Caption from "./Caption.vue"

export default {
  name: "AboutUs",
  components: { Caption },
  props: ["title", "titleCaption"],
  computed: {
    titleCaptionFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-light-white-130px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-light-white-90px";
          } else {
            return "poppins-light-white-50px";
          }
        case "xs":
          return "poppins-light-white-40px";
      }
    },
    titleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-50px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-34px";
          } else {
            return "poppins-bold-white-30px";
          }
        case "xs":
          return "poppins-bold-white-25px";
      }
    },
  },
};
</script>

<style>
.main-page-container {
  background: transparent linear-gradient(313deg, #18002e 0%, #310293 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  align-items: center;
  width: 100vw;
  padding-top: 90px;
  max-width: 1920px;
}
</style>
