<template>
  <div>
    <v-tooltip bottom :color="tooltipColor ?? color">
      <template v-slot:activator="{ on }">
        <v-btn
          elevation="2"
          rounded
          :color="color"
          style="text-transform: unset !important"
          class="px-8"
          v-on="on"
          @click="onClick"
          :outlined="outlined"
        >
          <span class="poppins-normal-white-16px">{{ buttonText }}</span>
        </v-btn>
      </template>
      <span :style="{ color: tooltipTextColor }" class="px-6">{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "TooltipButton",
  props: ["text", "tooltip", "color", "outlined", "tooltipColor", "tooltipTextColor"],
  data() {
    return {
      buttonText: this.text,
    };
  },
  methods: {
    onClick() {
      if (this.$isMobile()) {
        let tempText = this.buttonText;
        this.buttonText = 'Coming soon';
        setTimeout(() => {
          this.buttonText = tempText;
        }, 2000);
      }
    }
  }
};
</script>

<style></style>
