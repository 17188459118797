import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: "#310293",
            secondary: "#080293",
            accent: "#ff6200",
          },
        },
      },
});
