<template>
  <v-container fluid>
    <v-row style="width: 100vw; height: 90px; background-color: #310293"> </v-row>
    <v-row class="mt-6">
      <v-col align="center"><Button :text="'Button'" /></v-col
    ></v-row>
    <v-row class="mt-6">
      <v-col align="center"
        ><CircularProgress :value="69" class="comp-circ-progress" /></v-col
    ></v-row>
    <v-row class="mt-6">
      <v-col align="center"
        ><Category :item="tokenomicsCategories[0]" :isGlowing="true"
      /></v-col>
      <v-col align="center"
        ><Category :item="tokenomicsCategories[1]" :isGlowing="false"
      /></v-col>
      <v-col align="center"><Category :item="tokenomicsCategories[2]" /></v-col>
    </v-row>
    <v-row class="mt-6" style="background-color: #20015e">
      <v-col align="center"
        ><Categories :items="tokenomicsCategories" class="ml-10 my-6"
      /></v-col>
    </v-row>
    <v-row class="mt-6" style="background-color: #20015e">
      <v-col align="center" justify="center"
        ><CategoriesSlider :items="tokenomicsCategories" style="width: 600px"
      /></v-col>
    </v-row>
    <v-row class="mt-6" style="background-color: #20015e">
      <v-col align="center" justify="center">
        <div class="d-flex justify-space-around">
          <InfoBox
            v-for="card in businessCards"
            :key="card.icon"
            :icon="card.icon"
            :title="card.title"
            :text="card.text"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Button from "./Button.vue";
import CircularProgress from "./CircularProgress.vue";
import Category from "./tokenomics/Category.vue";
import { TokenomicsData, BusinessData } from "@/data.js";
import Categories from "./tokenomics/Categories.vue";
import CategoriesSlider from "./tokenomics/CategoriesSlider.vue";
import InfoBox from "./InfoBox.vue";

export default {
  name: "Components",
  components: {
    Button,
    CircularProgress,
    Category,
    Categories,
    CategoriesSlider,
    InfoBox,
  },
  props: [],
  data() {
    return {
      tokenomicsCategories: TokenomicsData.tokenomicsCategoriesProps,
      businessCards: BusinessData.cards,
    };
  },
};
</script>

<style>
.comp-circ-progress {
  width: 240px;
  height: 240px;
}
</style>
