<template>
  <div>
    <v-row no-gutters justify="center" style="gap: 24px">
      <v-col cols="auto">
        <a :href="email" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/mail.svg')" contain :height="maxIconSize"
            :width="maxIconSize"></v-img>
        </a>
      </v-col>
      <v-col cols="auto">
        <a :href="twitter" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/twitter.svg')" contain :height="maxIconSize"
            :width="maxIconSize"></v-img>
        </a>
      </v-col>
      <v-col cols="auto">
        <a :href="telegram" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/telegram.svg')" contain :height="maxIconSize"
            :width="maxIconSize"></v-img>
        </a>
      </v-col>
      <v-col cols="auto">
        <a :href="discord" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/discord.svg')" contain :height="maxIconSize"
            :width="maxIconSize"></v-img>
        </a>
      </v-col>
      <v-col cols="auto">
        <a :href="linkedin" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/linkedin.svg')" contain :height="maxIconSize"
            :width="maxIconSize"></v-img>
        </a>
      </v-col>
      <v-col cols="auto">
        <a :href="medium" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/medium.svg')" contain :height="maxIconSize"
            :width="maxIconSize"></v-img>
        </a>
      </v-col>
      <v-col cols="auto">
        <a :href="reddit" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/reddit.svg')" contain :height="maxIconSize"
            :width="maxIconSize"></v-img>
        </a>
      </v-col>
      <v-col cols="auto">
        <a :href="galxe" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/galxe.svg')" contain :height="maxIconSize"
            :width="maxIconSize"></v-img>
        </a>
      </v-col>
      <v-col cols="auto">
        <a :href="instagram" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/instagram.svg')" contain :height="maxIconSize"
            :width="maxIconSize"></v-img>
        </a>
      </v-col>
      <v-col cols="auto">
        <a :href="tiktok" target="_blank" rel="noopener noreferrer">
          <v-img :src="require('@/assets/img/socialmedia/tiktok.svg')" contain :height="maxIconSize"
            :width="maxIconSize"></v-img>
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SocialMediaIconsDark",
  props: ["email", "twitter", "telegram", "discord", "linkedin", "medium", "reddit", "galxe", "instagram", "tiktok"],
  computed: {
    maxIconSize() {
      return "48px"
    },
  },
};
</script>

<style></style>
