<template>
  <v-container class="container">
    <v-row v-if="!authenticated" class="my-16">
      <v-col>
        <v-btn @click="authenticate">Google Auth</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="authenticated" class="table-row my-16" justify="center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="data"
          :items-per-page="15"
          :search="search"
          :loading="loading"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :footer-props="{
            'items-per-page-options': [15, 50, 100, -1],
          }"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-card>
              <v-card-title>
                <div>Num duplicates: {{ numDuplicates }}</div>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  class="mx-4"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
            </v-card>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { getDatabase, ref, get, child } from "firebase/database";

export default {
  name: "AlphaReg",
  data() {
    return {
      user: null,
      needsAuth: false,
      authenticated: false,
      isAdmin: false,
      search: "",
      loading: true,
      sortBy: "timestamp",
      sortDesc: true,
      headers: [
        {
          text: "Email",
          align: "start",
          value: "email",
        },
        { text: "Name", value: "name" },
        { text: "OS", value: "os" },
        { text: "Timestamp", value: "timestamp" },
        { text: "ID", value: "id", sortable: false },
      ],
      data: [],
      numDuplicates: "",
    };
  },
  mounted() {
    const auth = getAuth();
    let clazz = this;
    auth.onAuthStateChanged(function (user) {
      if (user) {
        clazz.user = user;
        clazz.needsAuth = false;
        clazz.authenticated = true;
        clazz.checkAdmin();
      } else {
        clazz.needsAuth = true;
      }
    });
  },

  methods: {
    authenticate() {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();

      signInWithPopup(auth, provider)
        .then((result) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          this.user = result.user;
          this.authenticated = true;
          this.checkAdmin();
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(`Error during auth: ${errorMessage} (${errorCode})`);
        });
    },

    checkAdmin() {
      let clazz = this;
      const dbRef = ref(getDatabase());
      get(child(dbRef, `users/${this.user.uid}`))
        .then((snapshot) => {
          clazz.isAdmin = snapshot.val();
          this.fetchData();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchData() {
      let clazz = this;
      if (this.isAdmin) {
        const dbRef = ref(getDatabase());
        get(child(dbRef, `alpha`))
          .then((snapshot) => {
            var data = [];
            for (const [key, value] of Object.entries(snapshot.val())) {
              data.push({
                ...value,
                id: key,
              });
            }

            let numBeforeFilter = data.length;

            data = data.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.email === value.email)
            );

            clazz.numDuplicates = numBeforeFilter - data.length;

            clazz.data = data;
            clazz.loading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.container {
  background: transparent linear-gradient(313deg, #18002e 0%, #310293 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  align-items: center;
  width: 100vw;
  padding-top: 90px;
  max-width: 1920px;
}

.table-row {
  width: 100%;
}
</style>
