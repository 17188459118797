<template>
  <div class="caption-group">
    <div :class="[captionClass, left ? 'caption-left' : 'caption-center']" class="d-none d-md-block">
      {{ caption }}
    </div>
    <div :class="[textClass, left ? 'captioned-left' : 'captioned-center']">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Caption",
  props: {
    text: String,
    textClass: String,
    caption: String,
    captionClass: String,
    left: Boolean,
  }
};
</script>

<style>
.caption-group {
  position: relative;
  width: 100%;
}

.caption-left {
  opacity: 0.05;
  white-space: nowrap;
  margin-left: -0.5ch;
}

.caption-center {
  opacity: 0.05;
  text-align: center;
  white-space: nowrap;
}

.captioned-left {
  position: absolute;
  left: 0;
  bottom: 0;
}

.captioned-center {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 0;
}
</style>
