<template>
  <countdown :end-time="date" :speed="1000">
    <div slot="process" slot-scope="running">
      <div class="countdown-box">
        <svg class="cdt-svg" viewBox="0 0 300 300" xlink="http://www.w3.org/1999/xlink">
          <circle class="cdt-outer-data" id="cdt-days-outer-data" cx="150" cy="150" r="110" stroke="#3b00a0" />
          <circle class="cdt-data" id="cdt-days-data" cx="150" cy="150" r="110" stroke="#00ffff" stroke-linecap="round" />
          <text id="cdt-days-text" class="poppins-normal-70px" stroke="#00ffff" fill="#00ffff" y="150" x="150"
            text-anchor="middle">
            {{ animateDays(running.timeObj.d) }}
          </text>
          <text id="cdt-days-text" class="poppins-normal-50px" stroke="#00ffff" fill="#00ffff" y="210" x="150"
            text-anchor="middle">
            days
          </text>
        </svg>
      </div>
      <div class="countdown-box">
        <svg class="cdt-svg" viewBox="0 0 300 300" xlink="http://www.w3.org/1999/xlink">
          <circle class="cdt-outer-data" id="cdt-hours-outer-data" cx="150" cy="150" r="110" stroke="#3b00a0" />
          <circle class="cdt-data" id="cdt-hours-data" cx="150" cy="150" r="110" stroke="#00ffff"
            stroke-linecap="round" />
          <text id="cdt-hours-text" class="poppins-normal-70px" stroke="#00ffff" fill="#00ffff" y="150" x="150"
            text-anchor="middle">
            {{ animateHours(running.timeObj.h) }}
          </text>
          <text id="cdt-days-text" class="poppins-normal-50px" stroke="#00ffff" fill="#00ffff" y="210" x="150"
            text-anchor="middle">
            hours
          </text>
        </svg>
      </div>
      <div class="countdown-box">
        <svg class="cdt-svg" viewBox="0 0 300 300" xlink="http://www.w3.org/1999/xlink">
          <circle class="cdt-outer-data" id="cdt-minutes-outer-data" cx="150" cy="150" r="110" stroke="#3b00a0" />
          <circle class="cdt-data" id="cdt-minutes-data" cx="150" cy="150" r="110" stroke="#00ffff"
            stroke-linecap="round" />
          <text id="cdt-minutes-text" class="poppins-normal-70px" stroke="#00ffff" fill="#00ffff" y="150" x="150"
            text-anchor="middle">
            {{ animateMinutes(running.timeObj.m) }}
          </text>
          <text id="cdt-days-text" class="poppins-normal-50px" stroke="#00ffff" fill="#00ffff" y="210" x="150"
            text-anchor="middle">
            mins
          </text>
        </svg>
      </div>
      <div class="countdown-box">
        <svg class="cdt-svg" viewBox="0 0 300 300" xlink="http://www.w3.org/1999/xlink">
          <circle class="cdt-outer-data" id="cdt-seconds-outer-data" cx="150" cy="150" r="110" stroke="#3b00a0" />
          <circle class="cdt-data" id="cdt-seconds-data" cx="150" cy="150" r="110" stroke="#00ffff"
            stroke-linecap="round" />
          <text id="cdt-seconds-text" class="poppins-normal-70px" stroke="#00ffff" fill="#00ffff" y="150" x="150"
            text-anchor="middle">
            {{ animateSeconds(running.timeObj.s) }}
          </text>
          <text id="cdt-days-text" class="poppins-normal-50px" stroke="#00ffff" fill="#00ffff" y="210" x="150"
            text-anchor="middle">
            secs
          </text>
        </svg>
      </div>
    </div>
  </countdown>
</template>

<script>
import gsap from "gsap";

export default {
  name: "CountdownTimer",
  components: {},
  props: ["until"],
  data() {
    return {
      date: this.until,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      timeObj: {},
    };
  },
  activated() {
    gsap.set("#cdt-days-text", { autoAlpha: 1 });
    gsap.set("#cdt-hours-text", { autoAlpha: 1 });
    gsap.set("#cdt-minutes-text", { autoAlpha: 1 });
    gsap.set("#cdt-seconds-text", { autoAlpha: 1 });
    gsap.set("#cdt-days-data", { rotation: -90, transformOrigin: "center", drawSVG: "0" });
    gsap.set("#cdt-hours-data", { rotation: -90, transformOrigin: "center", drawSVG: "0" });
    gsap.set("#cdt-minutes-data", { rotation: -90, transformOrigin: "center", drawSVG: "0" });
    gsap.set("#cdt-seconds-data", { rotation: -90, transformOrigin: "center", drawSVG: "0" });
  },
  methods: {
    animateDays(value) {
      this.days = value;
      return value
    },
    animateHours(value) {
      this.hours = value
      return value
    },
    animateMinutes(value) {
      this.minutes = value
      return value
    },
    animateSeconds(value) {
      this.seconds = value
      return value
    },
    animateMe(data, value) {
      let duration = 0.5;
      let ease = "Power2.easeInOut";
      gsap.to(data, { drawSVG: `${value}%`, duration: duration, ease: ease });
    }
  },
  watch: {
    days: function (newValue, oldValue) {
      this.animateMe("#cdt-days-data", newValue > 0 ? 100 : 0);
    },
    hours: function (newValue, oldValue) {
      this.animateMe("#cdt-hours-data", newValue * 100 / 24);
    },
    minutes: function (newValue, oldValue) {
      this.animateMe("#cdt-minutes-data", newValue * 100 / 60);
    },
    seconds: function (newValue, oldValue) {
      this.animateMe("#cdt-seconds-data", newValue * 100 / 60);
    }
  }
};
</script>

<style>
.cdt-svg {
  position: relative;
  height: 100%;
  width: 100%;
}

.cdt-outer-data {
  stroke-width: 40;
  fill: none;
}

.cdt-data {
  stroke-width: 10;
  fill: none;
}

.countdown-box {
  width: 64px;
  height: 64px;
  display: inline-block;
}</style>
