import { defineStore } from 'pinia'
import { GetInTouchData } from '../data.js'

const FormMode = {
  Agent: Symbol("agent"),
  Business: Symbol("business"),
  Generic: Symbol("generic"),
}

export const useGetInTouchStore = defineStore('getintouch', {
  state: () => ({
    title: GetInTouchData.title[2],
    hasRadioGroup: true,
    entityType: 'agent',
  }),
  actions: {
    onAgentPage() {
        this.title = this.getTitle(FormMode.Agent);
        this.hasRadioGroup = false;
        this.entityType = 'agent';
    },
    onBusinessPage() {
        this.title = this.getTitle(FormMode.Business);
        this.hasRadioGroup = false;
        this.entityType = 'business';
    },
    onGenericPage() {
        this.title = this.getTitle(FormMode.Generic);
        this.hasRadioGroup = true;
        this.entityType = 'agent';
    },
    getTitle(mode) {
      switch (mode) {
        case FormMode.Agent:
          return GetInTouchData.title[0];
        case FormMode.Business:
          return GetInTouchData.title[1];
        case FormMode.Generic:
          return GetInTouchData.title[2];
      }
    }
  }
})