<template>
  <Page :title="title" :titleCaption="titleShadow">
    <v-row justify="center" class="mt-6 mt-md-10 mb-10">
      <v-col lg="9" cols="11">
        <div class="roadmap-top-content" :class="topContentFont">{{ topContent }}</div>
      </v-col>
    </v-row>
    <v-row class="roadmap-categories-bg mt-13 mb-13" justify="center" no-gutters>
      <v-col class="roadmap-content-col-bg" lg="10" md="11" cols="12">
        <div style="height: 1px; background-color: #00ffff"></div>

        <v-timeline :dense="$vuetify.breakpoint.smAndDown" class="my-6">
          <v-timeline-item
            v-for="(item, index) in roadmapItems"
            :key="item.label"
            hide-dot
            class="roadmap-item my-4"
            ref="titem"
          >
            <v-img
              :src="item.icon"
              slot="opposite"
              :class="index % 2 == 0 ? 'ml-auto' : 'mr-auto'"
              :width="iconWidth"
              contain
            ></v-img>
            <v-card class="elevation-2 roadmap-card rounded-lg py-6 px-9">
              <v-card-title>
                <div
                  :style="{
                    color: item.passed ? '#00FFFF !important' : '#FF5800 !important',
                    wordBreak: 'normal',
                  }"
                  :class="itemTitleFont"
                >
                  {{ item.label }}
                </div>
              </v-card-title>
              <v-card-text>
                <div class="roadmap-timeline-item-text" :class="itemContentFont">
                  {{ item.content }}
                </div>
              </v-card-text>
            </v-card>
          </v-timeline-item>

          <div class="roadmap-timeline-overlay" v-bind:style="glowLineStyle"></div>
        </v-timeline>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from "../Page.vue";

export default {
  name: "Roadmap",
  metaInfo: {
    title: "Roadmap"
  },
  components: { Page },
  props: ["titleShadow", "title", "topContent", "roadmapItems"],
  data() {
    return {
      glowLineStyle: {},
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.resized);
    });
    this.resized();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resized);
  },
  methods: {
    resized() {
      setTimeout(() => {
        let lastPassed = 0;
        let idx = 0;
        for (const item of this.roadmapItems) {
          if (item.passed) {
            lastPassed = idx;
          }
          idx++;
        }
        let cardPos = this.$refs.titem[lastPassed].$el.offsetTop;
        let cardHeight = this.$refs.titem[lastPassed].$el.clientHeight;
        // This motherfucker (-8) needs to compensate for top margin
        let height = cardPos + cardHeight / 2 - 8;

        let left;
        switch (this.$vuetify.breakpoint.name) {
          case "sm":
          case "xs":
            left = "calc(48px - 1px)";
            break;
          default:
            left = "calc(50% - 1px)";
            break;
        }

        this.glowLineStyle = {
          height: `${height}px`,
          left: left,
        };
      }, 200);
    },
  },
  computed: {
    topContentFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-18px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-16px";
          } else {
            return "poppins-normal-white-15px";
          }
        case "xs":
          return "poppins-normal-white-15px";
      }
    },
    itemTitleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-bold-white-32px";
        case "sm":
        case "xs":
          return "poppins-bold-white-24px";
      }
    },
    itemContentFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-18px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-normal-white-16px";
      }
    },
    iconWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "240";
        case "md":
        case "sm":
        case "xs":
          return "100";
      }
    },
  },
};
</script>

<style>
.roadmap-top-content {
  text-align: center;
  line-height: 27px;
}

.roadmap-categories-bg {
  width: 100%;
  overflow: hidden;
}

.roadmap-content-col-bg {
  background: #20015e 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 30px 30px;
  opacity: 1;
}

.roadmap-card {
  background-color: #310293 !important;
  border-color: #310293 !important;
}

.v-timeline-item__body {
  margin: 12px 24px;
}

.v-timeline::before {
  width: 10px !important;
  margin-left: -4px;
  background: #310293 !important;
  border-radius: 10px;
}

.roadmap-timeline-overlay {
  background-color: #00ffff;
  width: 10px;
  position: absolute;
  top: -2px;
  margin-left: -4px;
  border-radius: 10px;
}

.v-timeline-item__divider {
  min-width: 32px !important;
}

.roadmap-timeline-item-text {
  line-height: 27px;
}
</style>
