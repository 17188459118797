<template>
  <div class="extend-page-wrapper" v-on:click="$emit('click', $event)">
    <div ref="extendPage" class="extend-page pa-3 pa-sm-2 pa-lg-0">
    <img
      :class="$vuetify.breakpoint.smAndDown ? 'extend-page-icon-mobile' : 'extend-page-icon'"
      :src="require('@/assets/img/pluse-icon.svg')"
    />
    </div>
    <div ref="extendPagePulse" class="extend-page-pulse"></div>
  </div>
</template>

<script>
import { gsap, Back, Elastic, Expo } from "gsap"

export default {
  name: "ExtendPage",
  props: {
    opposite: Boolean
  },
  data() {
    return {
      timeline: null,
    }
  },
  mounted() {
    const { extendPage, extendPagePulse } = this.$refs;
    this.timeline = gsap.timeline({repeat: -1, repeatDelay: 4, delay: this.opposite ? 2 : 0});
    
    this.timeline.to(extendPage, 0.4, {
      scale: 0.8,
      rotation: this.opposite ? -16 : 16,
      ease: Back.easeOut.config(1.7),
    });
    this.timeline.to(
      extendPagePulse,
      0.3, 
      {
        scale: 0.5,
        opacity: 0.1,
      },
     '-=0.6' 
    );
    
    this.timeline.to(extendPage, 1.2, {
      scale: 1,
      rotation: this.opposite ? '+=16' : '-=16',
      ease: Elastic.easeOut.config(2.5, 0.5),
    });
    this.timeline.to(
      extendPagePulse,
      1.1,
      {
        scale: 3,
        opacity: 0,
        ease: Expo.easeOut,
      },
      '-=1.2'
    );
  }
};
</script>

<style>
.extend-page-wrapper {
  position: relative;
}

.extend-page {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  width: 54px;
}

.extend-page-pulse {
  position: absolute;
  z-index: 1;
  height: 80px;
  width: 80px;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0);
}

.extend-page-icon {
  width: 100%;
  height: 100%;
}

.extend-page-icon-mobile {
  width: 100%;
  height: 100%;
}
</style>
