<template>
  <v-container
    class="people flex-column"
    :style="{ paddingLeft: `${peoplePadding}px`, paddingRight: `${peoplePadding}px`}"
  >
    <v-row no-gutters id="top-text-people" class="mt-10">
      <v-col cols="12" align="center">
        <v-btn ref="userRegistration" elevation="3" rounded @click="$emit('openAlphaRegistration')" class="mt-10 mb-4 mb-md-0 mr-md-3" :style="{
            'background-color': '#310293',
            'color': 'white',
            'text-transform': 'unset !important',
            'padding': '20px',
          }">
            <div class="poppins-normal-16px">Test the app</div>
        </v-btn>
        <v-btn ref="loyaltyProgram" to="/article?catch-the-rewards-loyalty-airdrop" target="_blank" elevation="3" outlined rounded class="mt-2 mb-10 mt-md-10 mb-md-0 ml-md-3" :style="{
            'color': 'white',
            'text-transform': 'unset !important',
            'padding': '20px',
          }">
            <div class="poppins-normal-16px">Loyalty Program</div>
        </v-btn>
      </v-col>
      <v-col align="center">
        <h1
          :class="[titleCaptionFont, 'd-none', 'd-md-inline']"
          style="opacity: 0.05; white-space: nowrap; overflow: hidden"
        >
          What’s it all about
        </h1>
        <div
          :class="[titleFont, 'mt-12', 'mt-md-0']"
          style="position: relative; top: -25%"
        >
          {{ peopleTitle11Props.whatAreTheTasksT }}
        </div>
      </v-col>
    </v-row>

    <v-row justify="center" align="end" class="ma-0">
      <v-col
        :cols="pictogramNumColumns"
        id="tag-emotions"
        align="center"
        justify="space-between"
      >
        <div
          :class="[normalTextFont, 'px-0', 'px-sm-8']"
          :style="{ 'text-align': 'center' }"
        >
          {{
            explainVideo4Props.explainWhatsHappeningInAVideoProps
              .explainWhatsHappeningInAVideo
          }}
        </div>
        <lottie :options="tagEmotionsOptions" class="px-sm-10 px-lg-0" />
      </v-col>
      <v-col
        :cols="pictogramNumColumns"
        id="whats-in-picture"
        align="center"
        justify="space-between"
      >
        <div
          :class="[normalTextFont, 'px-0', 'px-sm-8']"
          :style="{ 'text-align': 'center', }"
        >
          {{
            explainVideo3Props.explainWhatsHappeningInAVideoProps
              .explainWhatsHappeningInAVideo
          }}
        </div>
        <lottie :options="explainPicOptions" class="px-sm-10 px-lg-0" />
      </v-col>
      <v-col
        :cols="pictogramNumColumns"
        id="labeling-objects"
        align="center"
        justify="space-between"
      >
        <div
          :class="[normalTextFont, 'px-0', 'px-sm-8']"
          :style="{ 'text-align': 'center' }"
        >
          {{
            explainVideo2Props.explainWhatsHappeningInAVideoProps
              .explainWhatsHappeningInAVideo
          }}
        </div>
        <lottie :options="labelingOptions" class="px-sm-10 px-lg-0" />
      </v-col>
      <v-col
        :cols="pictogramNumColumns"
        id="explain-video"
        align="center"
        justify="space-between"
      >
        <div
          :class="[normalTextFont, 'px-0', 'px-sm-8']"
          :style="{ 'text-align': 'center' }"
        >
          {{
            explainVideo1Props.explainWhatsHappeningInAVideoProps
              .explainWhatsHappeningInAVideo
          }}
        </div>
        <lottie :options="videoOptions" class="px-sm-10 px-lg-0" />
      </v-col>
    </v-row>

    <v-row no-gutters id="bottom-title" class="mt-sm-8 mt-lg-16">
      <v-col>
        <v-row justify="center">
          <v-col cols="10" lg="8">
            <div :class="titleFont" style="text-align: center">
              {{ peopleTitle12Props.whatAreTheTasksT }}
            </div>
          </v-col>
        </v-row>
        <v-row class="mx-2" justify="space-around">
          <v-col :cols="bottomTextNumColumns" class="pa-lg-6">
            <div
              :class="normalTextFont"
              :style="{ 'text-align': 'center', 'line-height': bottomLineHeight }"
            >
              {{
                peopleText11Props.explainWhatsHappeningInAVideoProps
                  .explainWhatsHappeningInAVideo
              }}
            </div>
          </v-col>
          <v-col :cols="bottomTextNumColumns" class="pa-lg-6">
            <div
              :class="normalTextFont"
              :style="{ 'text-align': 'center', 'line-height': bottomLineHeight }"
            >
              {{
                peopleText12Props.explainWhatsHappeningInAVideoProps
                  .explainWhatsHappeningInAVideo
              }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { gsap } from "gsap"
import Lottie from "vue-lottie";
import tagEmotionsData from "@/assets/people/2-social-media.json";
import explainPicData from "@/assets/people/4-scan.json";
import labelingData from "@/assets/people/3-labeling.json";
import videoData from "@/assets/people/1-video-com.json";

export default {
  name: "People",
  components: {
    Lottie,
  },
  data() {
    return {
      tagEmotionsOptions: { animationData: tagEmotionsData, autoplay: true },
      explainPicOptions: { animationData: explainPicData, autoplay: true },
      labelingOptions: { animationData: labelingData, autoplay: true },
      videoOptions: { animationData: videoData, autoplay: true },
    };
  },
  computed: {
    peoplePadding() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.earthGap / 2 + 25;
        case "sm":
        case "md":
          return this.earthGap / 2 + 50;
        default:
          return this.earthGap / 2 + 100;
      }
    },
    bottomLineHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "30px";
        default:
          return "20px";
      }
    },
    normalTextFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
            return "poppins-normal-white-20px";
          case "lg":
            return "poppins-normal-white-18px";
          case "md":
          case "sm":
            if (!this.$isMobile()) {
              return "poppins-normal-white-18px";
            } else {
              return "poppins-normal-white-16px";
            }
          case "xs":
            return "poppins-normal-white-16px";
      }
    },
    bottomTextNumColumns() {
      if (this.$isMobile()) {
        return 12;
      } else {
        switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
            return 6;
          case "md":
            return 5;
          case "xs":
          case "sm":
            return 4;
        }
      }
    },
    titleFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
            return "poppins-bold-white-50px";
          case "md":
          case "sm":
            if (!this.$isMobile()) {
              return "poppins-bold-white-40px";
            } else {
              return "poppins-bold-white-25px";
            }
          case "xs":
            return "poppins-bold-white-25px";
      }
    },
    titleCaptionFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
            return "poppins-light-white-130px";
          case "lg":
            return "poppins-light-white-110px";
          case "md":
          case "sm":
            if (!this.$isMobile()) {
              return "poppins-light-white-70px";
            } else {
              return "poppins-light-white-45px";
            }
          case "xs":
            return "poppins-light-white-45px";
      }
    },
    pictogramNumColumns() {
        switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
            return 3;
          case "md":
          case "sm":
          case "xs":
            return 6;
      }
    },
  },
  props: [
    "peopleTitle11Props",
    "peopleText11Props",
    "peopleText12Props",
    "peopleTitle12Props",
    "explainVideo1Props",
    "xVideoProps",
    "explainVideo2Props",
    "labelingProps",
    "explainVideo3Props",
    "scanProps",
    "explainVideo4Props",
    "tagEmotionProps",
    "earthGap",
  ],
  mounted() {
    gsap.from("#top-text-people", { y: "-400px", duration: 1 });

    gsap.from("#tag-emotions", { x: "-600px", opacity: 0, duration: 1 });
    gsap.from("#whats-in-picture", { x: "-500px", opacity: 0, duration: 1 });
    gsap.from("#labeling-objects", { x: "-400px", opacity: 0, duration: 1 });
    gsap.from("#explain-video", { x: "-300px", opacity: 0, duration: 1 });

    gsap.from("#bottom-title", { y: "400px", opacity: 0, duration: 1 });

    const userRegistration = this.$refs.userRegistration.$el;
    const timeline = gsap.timeline({repeat: -1, repeatDelay: 4, delay: 0});
    
    timeline.to(userRegistration, {
      duration: 0.1,
      scale: 4,
      rotation: 180,
      ease: "bounce",
    });

    timeline.to(userRegistration, {
      duration: 0.1,
      scale: 4,
      rotation: -180,
      ease: "bounce",
    });
    
    timeline.to(userRegistration, {
      duration: 0.1,
      scale: 1,
      rotation: 0,
      ease: "linear",
    });

    const loyaltyProgram = this.$refs.loyaltyProgram.$el;
    const timeline2 = gsap.timeline({repeat: -1, repeatDelay: 4, delay: 2});
    
    timeline2.to(loyaltyProgram, {
      duration: 0.1,
      scale: 4,
      rotation: 180,
      ease: "bounce",
    });

    timeline2.to(loyaltyProgram, {
      duration: 0.1,
      scale: 4,
      rotation: -180,
      ease: "bounce",
    });
    
    timeline2.to(loyaltyProgram, {
      duration: 0.1,
      scale: 1,
      rotation: 0,
      ease: "linear",
    });
    
  },
  methods: {
    register() {
      this.$router.push("/alpha").catch((err) => { console.log(err) });
    }
  },
};
</script>

<style>
.people {
  max-width: 1920px;
  width: 100vw;
  min-height: calc(var(--vh, 1vh) * 100);
}
</style>
