<template>
  <div class="footer pt-10">
    <social-media-icons v-bind="$props.socialProps" />
    <get-in-touch v-bind="$props.getInTouchProps" />
    <v-container class="footer-1 mt-10" fluid>
      <div class="separator mx-6 mx-lg-16"></div>
      <v-row class="space-between mx-6 mx-lg-16" align="start">
        <v-col cols="6" sm="4" md="2">
          <img
            :src="require('@/assets/img/logo/logo-white-secondary-tagline-nopad.svg')"
            style="object-fit: cover;"
            class="ml-n2 mt-5 mt-sm-6 mt-md-8"
          />
        </v-col>
      </v-row>
      <v-row class="mx-6 mx-lg-16">
        <v-col cols="4">
          <p :class="textFont">
            {{ x2022TimeworxAllRightsReserved }}
          </p>
        </v-col>
        <v-col cols="8" class="mb-5" align="end">
          <div class="d-flex flex-wrap justify-end">
            <div class="px-2" :class="textFont">{{ termsAndConditions }}</div>
            <router-link to="/privacy"><div class="px-2" :class="textFont">{{ privacyPolicy }}</div></router-link>
            <router-link to="/faq"><div class="px-2" :class="textFont">{{ faq }}</div></router-link>
            <div class="px-2" :class="textFont">{{ contactDetails }}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import GetInTouch from "./GetInTouch.vue";
import SocialMediaIcons from "./SocialMediaIcons.vue";

export default {
  name: "Footer",
  components: {
    SocialMediaIcons,
    GetInTouch,
  },
  props: [
    "x2022TimeworxAllRightsReserved",
    "termsAndConditions",
    "privacyPolicy",
    "faq",
    "contactDetails",
    "getInTouchProps",
    "socialProps",
  ],
  computed: {
    textFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-16px";
        default:
          return "poppins-normal-white-12px";
      }
    },
  },
};
</script>

<style scoped>
.footer {
  align-items: center;
  background-color: var(--blue-gem);
  display: flex;
  flex-direction: column;
  padding: 52px 0;
  position: relative;
  max-width: 1920px;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
}

.footer-1 {
  max-width: 1920px;
  width: 100vw;
}
</style>
