<template>
  <Page class="blog-bg">
    <v-container class="blog mt-n16 mt-md-0">
      <v-row>
        <h2 :class="headlineFont" class="mt-n4 mt-md-10">Discover more topics</h2>
      </v-row>
      <v-row class="mt-6 mb-2">
        <v-chip-group v-model="selectedLabel" @change="selectSpecificLabels()">
          <v-chip outlined color="#34323A" text-color="#B09EFF" style="border-color: #B09EFF;"
            class="article-label mr-3" v-for="label in labels"
          >
            <span :class="labelFont">{{ label }}</span>
          </v-chip>
        </v-chip-group>
      </v-row>
      <v-row class="py-4 py-md-10">
        <ArticleListItem v-for="(article, index) in articles" :article="article" :class="{ 'active': index === 0 }" />
      </v-row>
    </v-container>
  </Page>
</template>

<script>
import Page from "../Page.vue";
import ArticleListItem from "./ArticleListItem.vue"
import { getFirestore, collection, getDocs, query, where, orderBy } from "firebase/firestore";

export default {
  name: "Blog",
  metaInfo: {
    title: "Blog"
  },
  components: { Page, ArticleListItem },
  props: [],
  data() {
    return {
      articles: [],
      allLabels: 0,
      selectedLabel: 0,
      labels: [],
    };
  },
  async mounted() {
    this.runQuery();
  },
  computed: {
    headlineFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-24px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-semi-bold-white-16px";
      }
    },
    labelFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-14px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-normal-12px";
      }
    },
  },
  methods: {
    navigate(link) {
      window.open(link, '_blank', 'noreferrer');
    },
    formatDate(date) {
      const month = date.toLocaleString('default', { month: 'short' });
      return `${month} ${date.getDate()}, ${date.getFullYear()}`;
    },
    async runQuery() {
      const q = query(collection(getFirestore(), "blog"), where('draft', '==', false), orderBy("date"))
      const querySnapshot = await getDocs(q);
      let topics = new Set();

      let rawArticles = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        rawArticles.unshift({
            date: this.formatDate(data.date.toDate()),
            time: data.reading_time_minutes,
            title: data.title,
            intro: data.intro,
            featured: data.featured,
            link: data.link ?? this.resolveLink(data.tag),
            featuredImage: data.featured_img_src,
            labels: data.topics,
          });

        data.topics.forEach((topic) => { topics.add(topic) });
      });

      this.labels = ["All"];
      topics.forEach((label) => { this.labels.push(label) });

      let selectedTopics = this.selectedLabel === 0 ? this.labels : [this.labels[this.selectedLabel]];
      this.articles = rawArticles.filter((article) => {
        return article.labels.some((label) => selectedTopics.includes(label));
      });
    },
    resolveLink(tag) {
      return this.$router.resolve({ path: "/article", query: { [tag]: null } }).href
    },
    selectSpecificLabels() {
      if (this.selectedLabel === undefined) {
        this.selectedLabel = 0;
      }
      this.runQuery();
    }
  }
};
</script>

<style scoped>
.blog-bg {
  background: black;
}

.blog {
  max-width: 1280px;
}
</style>
