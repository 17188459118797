import Vue from "vue";
import Router from "vue-router";
import Home from "./components/Home";
import { HomeData, TokenomicsData, RoadmapData, BusinessData, AgentData, AboutUsData, LinkTreeData, AirdropsData } from "./data";
import Components from "./components/Components"
import HomeV2 from "./components/home/Home"
import Tokenomics from "./components/tokenomics/Tokenomics"
import Roadmap from "./components/roadmap/Roadmap"
import AboutUs from "./components/aboutus/AboutUs"
import PrivacyPolicy from "./components/PrivacyPolicy"
import LinkTree from "./components/LinkTree"
import FAQ from "./components/FAQ"
import Blog from "./components/blog/Blog"
import BlogArticle from "./components/blog/BlogArticle"
import InstallMobile from "./components/misc/Install"
import Airdrops from "./components/airdrops/Airdrops"

import AlphaReg from "./components/admin/AlphaReg.vue";

const config = require("./config");

Vue.use(Router);

const routes = [
  {
    path: "/tokenomics",
    component: Tokenomics,
    props: { ...TokenomicsData },
  },
  {
    path: "/roadmap",
    component: Roadmap,
    props: { ...RoadmapData },
  },
  {
    path: "/business",
    component: Home,
    props: { ...HomeData, businessExpanded: true },
  },
  {
    path: "/platform",
    component: Home,
    props: { ...HomeData, businessExpanded: true },
  },
  {
    path: "/agent",
    component: Home,
    props: { ...HomeData, agentExpanded: true },
  },
  {
    path: "/community",
    component: Home,
    props: { ...HomeData, agentExpanded: true },
  },
  {
    path: "/alpha",
    component: Home,
    props: { ...HomeData, agentExpanded: true, displayAlphaRegistration: true },
  },
  {
    path: "/aboutus",
    component: AboutUs,
    props: { ...AboutUsData },
  },
  {
    path: "/privacy",
    component: PrivacyPolicy,
    props: {}
  },

  {
    path: "/alphareg",
    component: AlphaReg,
    props: {}
  },

  {
    path: "/links",
    component: LinkTree,
    props: { ...LinkTreeData },
  },

  {
    path: "/faq",
    component: FAQ,
    props: {},
  },

  {
    path: "/blog",
    component: Blog,
    props: {},
  },

  {
    path: '/article',
    name: "BlogArticle",
    component: BlogArticle,
    props: {},
  },

  {
    path: "/mobile",
    component: InstallMobile,
    props: {},
  },

  {
    path: "/airdrops",
    component: Airdrops,
    props: { ...AirdropsData },
  },

  {
    path: "/:pathMatch(.*)*",
    component: HomeV2,
    props: { ...HomeData },
  },
];

if (config.hasCompontentsPage) {
  console.log("Adding /components route");
  routes.splice(0, 0, {
    path: "/components",
    component: Components,
  });
}

export default new Router({
  mode: "history",
  routes: routes,
  scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
});
